import React from 'react';
import phSmall from '../../assets/images/ph-small.jpg';
import swal from 'sweetalert';
import { deleteEmpleoByID } from '../../services/apis';

{/* Texto  */}
export default function MisAvisosList({avisos}) {
    let url = "/gestion-candidato/";
    let url_update = "/editar-aviso/";
    var idEmpleoBase64 = "";

    function routeChange(id) {
        
        idEmpleoBase64 = Buffer.from(id.toString()).toString('base64');
        localStorage.setItem('aviso', idEmpleoBase64 );

        let path = url+id;
        console.log(path);
        //navigate(path);
        window.location.href = path;

        /*window.open(
            path,
            '_blank'
        );*/
    }

    function avisoUpdate(id) {
        
        //idEmpleoBase64 = Buffer.from(id.toString()).toString('base64');
        //localStorage.setItem('avisoUP', idEmpleoBase64 );

        let path = url_update+id;
        //console.log(path);
        //navigate(path);
        window.location.href = path;

        /*window.open(
            path,
            '_blank'
        );*/
    }

    const eliminar = async (id) => {
        //e.preventDefault();
        
        swal({
            title: "¿Estas seguro?",
            text: "Una vez eliminado, ¡no podrá recuperar este item!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then(async (willDelete) => {
            if (willDelete) {

                const response = await deleteEmpleoByID(id);
            
                if ('status' in response) {
                    swal("¡Éxito!", "Se borro correctamente", "success");
                    window.location.href = "/mis-avisos";
                } else {
                    swal("Error", "Ocurrio un error al eliminar", "error");
                }

            } else {
                //confirmacion = False;
            }
        });
        
    }

    return (
        <>
                {
                    avisos.map(({id, nombre, categoria, tipoContratoEnum, cargo},index)=>{
                        return <div className="pxp-jobs-card-3 pxp-has-border" key={index}>
                            <div className="row align-items-center justify-content-between">
                                <div className="col-sm-3 col-md-2 col-lg-3 col-xl-2 col-xxl-auto">
                                    <a href="javascript: void(0)" onClick={() => routeChange(id)} className="pxp-jobs-card-3-company-logo" style={{backgroundImage: `url(${phSmall})`}}></a>
                                </div>
                                <div className="col-sm-9 col-md-10 col-lg-9 col-xl-10 col-xxl-4">
                                    <a href="javascript: void(0)" onClick={() => routeChange(id)} className="pxp-jobs-card-3-title mt-3 mt-sm-0">{nombre}</a>
                                    <div className="pxp-jobs-card-3-details">
                                        <a href="javascript: void(0)" onClick={() => routeChange(id)} className="pxp-jobs-card-3-location">
                                            <span className="fa fa-globe"></span>{categoria}
                                        </a>
                                        <div className="pxp-jobs-card-3-type" style={{"display":"none"}}>Full-time</div>
                                    </div>
                                </div>
                                <div className="col-sm-8 col-xl-6 col-xxl-4 mt-3 mt-xxl-0">
                                    <a href="javascript: void(0)" onClick={() => routeChange(id)} className="pxp-jobs-card-3-category">
                                        <div className="pxp-jobs-card-3-category-label">{cargo}</div>
                                    </a>
                                    <div className="pxp-jobs-card-3-date-company">
                                        <span className="pxp-jobs-card-3-date pxp-text-light">{tipoContratoEnum.name}</span>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-xl-2 col-xxl-auto mt-3 mt-xxl-0 pxp-text-right">
                                    <div className="pxp-dashboard-table-options">
                                        <ul className="list-unstyled">
                                            <li><button title="Ver anuncio" onClick={() => routeChange(id)} ><span className="fa fa-eye"></span></button></li>
                                            <li ><button title="Editar anuncio" onClick={() => avisoUpdate(id)}><span className="fa fa-pencil"></span></button></li>
                                            <li ><button title="Eliminar anuncio" onClick={() => eliminar(id)} ><span className="fa fa-trash-o"></span></button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>;
                    })
                }
        </>
    );
};
import React,{useState, useEffect} from 'react';
import { findListaEmpleosByCandidato, findListaEmpleosByCandidatoFiltro } from '../../../services/apis';
import CandidatoBarra from './CandidatoBarra';
import CandidatoLista from './CandidatoLista';

export default function Section1({candidatoID}) {

    //if(!candidatoID) return null;

    const [search, setSearch] = useState(null);
    const [busqueda, setBusqueda] = useState(null);

    useEffect( () => {

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                if(busqueda){
                    const{cg_nombre, cg_procesoseleccion} = busqueda;

                    const res = await findListaEmpleosByCandidatoFiltro(candidatoID, cg_procesoseleccion, cg_nombre );
                    console.log(res);
                    setSearch(res);
                } else {
                    const res = await findListaEmpleosByCandidatoFiltro(candidatoID, "", "" );
                    console.log(res);
                    setSearch(res);
                }

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[busqueda]);


    const handleSearch = (data) => {
        //alert(data);
        setBusqueda(data);
    }

    return (
        <section >
            <div className="pxp-container">
                <div className="row">
                    <div className="col-lg-5 col-xl-4 col-xxl-3">
                        <CandidatoBarra handleSearch = {handleSearch} />
                    </div>
                    <div className="col-lg-7 col-xl-8 col-xxl-9">
                        
                        <CandidatoLista search ={search} />

                    </div>
                </div>
            </div>
        </section>
    )
}
import React,{useState, useEffect} from 'react';
import { findByParameters, findCandidatosByEmpresa, findEmpresaByID, findListaEmpleosByEmpresa } from '../../../services/apis';

import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import InicioDashboard from './InicioDashboard';

export default function Section1() {

    const company = JSON.parse(localStorage.getItem('company'));
    const GetUserID = company.codeId;

    const [search, setSearch] = useState(null);
    const [empresa, setEmpresa] = useState(null);

    const [jobList, setJobList] = useState(null);
    const [jobDetail, setJobDetail] = useState(null);
    const [loading, setLoading] = useState(false);

    const getPuesto = null;
    const getUbicacion = null;
    const getCompania = GetUserID; //1;

    useEffect( async () => {

        window.scrollTo(0, 0); // FORZA TOP

        const jobTipoA = 1;
        const jobModalidadB = 1;
        const companiaID = GetUserID; //1

        if(search){
            const{jobsearch, joblocation, jobDestacado, jobUrgente, jobTipoContrato} = search;
            console.log("USE JOBSEARCH A");
            
            const jobsList = await findByParameters(jobsearch, joblocation, jobDestacado, jobUrgente, companiaID, jobTipoContrato);
            console.log(jobsList);
            setJobList(jobsList);
            //setSearch(jobsList);

        } else if( (getPuesto || getUbicacion) ){
            console.log("USE JOBSEARCH B");

            const jobsList = await findByParameters(getPuesto, getUbicacion, jobTipoA, jobModalidadB, companiaID);
            setJobList(jobsList);
            //setSearch(jobsList);

        } else if( getCompania ){
            console.log("USE JOBSEARCH C");

            const jobsList = await findByParameters("", "", "", "", GetUserID);
            console.log(jobsList);
            
            setJobList(jobsList);
            //setSearch(jobsList);
        } else {
            console.log("USE JOBSEARCH D");
        }


        try {
            const res = await findCandidatosByEmpresa(GetUserID);
            console.log(res);
            setJobDetail(res);

        } catch (e) {
            console.error(e);
        }


        async function fetchData() {

            // RUC
            try {
                const res = await findEmpresaByID(GetUserID);
                console.log(res);
                setEmpresa(res);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[search]);
    

    const handleSearch = (data) => {
        setSearch(data);
    }

    return ( 
        <div className="pxp-dashboard-content-details">
                <h1>{empresa ? empresa.razonSocial : ""}</h1>
                <p className="pxp-text-light">Bienvenido usuario administrador.</p>

                <InicioDashboard jobList = {jobList} jobDetail={jobDetail} />

                <div className="mt-4 mt-lg-5" style={{"display":"none"}}>
                    <h2>Últimas publicaciones</h2>
                    <div className="table-responsive">
                        <table className="table align-middle">
                            <tr>
                                <td style={{width: "3%"}}><div className="pxp-company-dashboard-candidate-avatar pxp-cover" style={{ backgroundImage: `url(${phSmall})` }} ></div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-name">Técnico de planta interna</div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-title">Apurimac</div></td>
                                <td><div className="pxp-company-dashboard-candidate-location"><span className="fa fa-globe"></span>3</div></td>
                                <td>
                                    <div className="pxp-dashboard-table-options">
                                        <ul className="list-unstyled">
                                            <li><button title="View profile"><span className="fa fa-eye"></span></button></li>
                                            
                                            <li><button title="Delete"><span className="fa fa-trash-o"></span></button></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "3%"}}><div className="pxp-company-dashboard-candidate-avatar pxp-cover" style={{ backgroundImage: `url(${phSmall})` }} ></div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-name">Técnico Linero</div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-title">Huancavelica</div></td>
                                <td><div className="pxp-company-dashboard-candidate-location"><span className="fa fa-globe"></span>5</div></td>
                                <td>
                                    <div className="pxp-dashboard-table-options">
                                        <ul className="list-unstyled">
                                            <li><button title="View profile"><span className="fa fa-eye"></span></button></li>
                                            
                                            <li><button title="Delete"><span className="fa fa-trash-o"></span></button></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "3%"}}><div className="pxp-company-dashboard-candidate-avatar pxp-cover" style={{ backgroundImage: `url(${phSmall})` }}></div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-name">Técnico fusionador</div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-title">Amazonas</div></td>
                                <td><div className="pxp-company-dashboard-candidate-location"><span className="fa fa-globe"></span>1</div></td>
                                <td>
                                    <div className="pxp-dashboard-table-options">
                                        <ul className="list-unstyled">
                                            <li><button title="View profile"><span className="fa fa-eye"></span></button></li>
                                            
                                            <li><button title="Delete"><span className="fa fa-trash-o"></span></button></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "3%"}}><div className="pxp-company-dashboard-candidate-avatar pxp-cover" style={{ backgroundImage: `url(${phSmall})` }}></div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-name">Técnico de planta</div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-title">Ayacucho</div></td>
                                <td><div className="pxp-company-dashboard-candidate-location"><span className="fa fa-globe"></span>3</div></td>
                                <td>
                                    <div className="pxp-dashboard-table-options">
                                        <ul className="list-unstyled">
                                            <li><button title="View profile"><span className="fa fa-eye"></span></button></li>
                                            
                                            <li><button title="Delete"><span className="fa fa-trash-o"></span></button></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "3%"}}><div className="pxp-company-dashboard-candidate-avatar pxp-cover" style={{ backgroundImage: `url(${phSmall})` }}></div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-name">Supervisor de planta</div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-title">Cusco</div></td>
                                <td><div className="pxp-company-dashboard-candidate-location"><span className="fa fa-globe"></span>3</div></td>
                                <td>
                                    <div className="pxp-dashboard-table-options">
                                        <ul className="list-unstyled">
                                            <li><button title="View profile"><span className="fa fa-eye"></span></button></li>
                                            
                                            <li><button title="Delete"><span className="fa fa-trash-o"></span></button></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: "3%"}}><div className="pxp-company-dashboard-candidate-avatar pxp-cover" style={{ backgroundImage: `url(${phSmall})` }}></div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-name">Técnico de campo</div></td>
                                <td style={{width: "25%"}}><div className="pxp-company-dashboard-candidate-title">Cusco</div></td>
                                <td><div className="pxp-company-dashboard-candidate-location"><span className="fa fa-globe"></span>2</div></td>
                                <td>
                                    <div className="pxp-dashboard-table-options">
                                        <ul className="list-unstyled">
                                            <li><button title="View profile"><span className="fa fa-eye"></span></button></li>
                                            
                                            <li><button title="Delete"><span className="fa fa-trash-o"></span></button></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div className="row mt-4 mt-lg-5" style={{"display":"none"}}>
                    <div className="col-xxl-6">
                        <h2>Postulantes recientes</h2>
                        <div className="pxp-dashboard-notifications">
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-briefcase"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Scott Goodwin</a> applied for <a href="single-job-1.html">Software Engineer</a>.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">20m ago</div>
                            </div>
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-envelope-o"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Kenneth Spiers</a> sent you a message.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">1h ago</div>
                            </div>
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-briefcase"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Rebecca Eason</a> applied for <a href="single-job-1.html">Team Leader</a>.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">3h ago</div>
                            </div>
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-briefcase"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Scott Goodwin</a> applied for <a href="single-job-1.html">Software Engineer</a>.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">1d ago</div>
                            </div>
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-envelope-o"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Scott Goodwin</a> sent you a message.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">2d ago</div>
                            </div>
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-briefcase"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Kenneth Spiers</a> applied for <a href="single-job-1.html">Team Leader</a>.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">2d ago</div>
                            </div>
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-briefcase"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Rebecca Eason</a> applied for <a href="single-job-1.html">Software Engineer</a>.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">3d ago</div>
                            </div>
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-envelope-o"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Alayna Becker</a> sent you a message.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">1w ago</div>
                            </div>
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-briefcase"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Kenneth Spiers</a> applied for <a href="single-job-1.html">Team Leader</a>.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">1w ago</div>
                            </div>
                            <div className="pxp-dashboard-notifications-item mb-3">
                                <div className="pxp-dashboard-notifications-item-left">
                                    <div className="pxp-dashboard-notifications-item-type"><span className="fa fa-briefcase"></span></div>
                                    <div className="pxp-dashboard-notifications-item-message">
                                        <a href="single-candidate-1.html">Rebecca Eason</a> applied for <a href="single-job-1.html">Software Engineer</a>.
                                    </div>
                                </div>
                                <div className="pxp-dashboard-notifications-item-right">1mo ago</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
     )
}
import React from 'react';
import { useFormE } from '../../../hooks/useFormE';

const initialForm = {
    idCompania: 1,
    nombreComercial: "",
    razonSocial: "",
    pais: "1",
    departamento: "1",
    provincia: "1",
    distrito: "1",
    direccion: "",
    sectorEmpresa: "1",
    nroTrabajadores: "1"
};

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.nombreComercial.trim()){
        errors.nombreComercial = "El campo Nombre es requerido";
    } else if( !regexNombres.test(form.nombreComercial.trim()) ) {
        errors.nombreComercial = "El campo Nombre solo acepta letras y espacios";
    }

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

//export default function Section1({, , }) {
const Section1 = ({search, paises, departamentos, provincias, distritos}) => {

    /*if(!search) return null;
    if(!paises) return null;
    if(!departamentos) return null;*/

    const {
        form, 
        errors, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormE(initialForm, validationsForms);

    return (
        <form onSubmit={handleSubmit} >
            <div className="mt-4 mt-lg-5">
                <h2>Detalles de la empresa</h2>
                <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Nombre de la empresa</label>
                                    <input type="text" name="nombreComercial" className="form-control" placeholder={search ? search.nombreComercial : ""} onBlur={handleBlur} value={form.nombreComercial} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                    <label className="form-label">Razón social</label>
                                    <input type="text" name="razonSocial" className="form-control" placeholder={search ? search.razonSocial : ""} value={form.razonSocial}  onChange={handleChange} />
                                </div>
                            </div>
                </div>
                <div className="row">
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="pxp-company-country" className="form-label">País</label>
                                    <select className="form-control" id="pais" name='pais' onChange={handleChange} defaultValue={1} >
                                        {
                                            paises
                                            ?
                                            paises.map(({id, nombre},index)=>{
                                                return <option value={id} key={index} >{nombre}</option>
                                            })
                                            :
                                            null
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="pxp-company-city" className="form-label">Departamento</label>
                                    <select className="form-control" id="departamento" name='departamento' onChange={handleChange} defaultValue={1} >
                                        {
                                            departamentos
                                            ?
                                            departamentos.map(({id, nombre},index)=>{
                                                return <option value={id} key={index} >{nombre}</option>
                                            })
                                            :
                                            null
                                        }
                                    </select>
                                </div>
                            </div>
                            
                </div>
                <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <label className="form-label">Direción</label>
                                    <input type="text" name="direccion" className="form-control" placeholder={search ? search.direccion : "" } value={form.direccion} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="col-md-6" style={{"display": "none"}}>
                                <div className="mb-3">
                                    <label className="form-label">Sector de la Empresa</label>
                                    <input type="text" name="sectorEmpresa" className="form-control" placeholder="Sector de la Empresa" value="" />
                                </div>
                            </div>
                            <div className="col-md-6" style={{"display": "none"}}>
                                <div className="mb-3">
                                    <label className="form-label">Número de trabajadores</label>
                                    <input type="text" name="nroTrabajadores" className="form-control" placeholder="Número de trabajadores" value="" />
                                </div>
                            </div>
                </div>
                <div className="mt-4 mt-lg-5 text-center">
                    <button type="submit" className="btn rounded-pill pxp-section-cta">Guardar</button>
                </div>
            </div>
        </form>
    )
}


export default Section1;
import React from 'react';
import FormConfiguracionPassword from '../../../components/Conf-password/FormConfiguracionPassword';

export default function ConfiguracionPassword({search}) {

    if(!search) return null;

    return (
        <>
            <tr>
                <td style={{width: "80%"}}>
                    <div className="pxp-candidate-dashboard-experience-title">Contraseña</div>
                    <div className="pxp-candidate-dashboard-experience-school">********</div>
                </td>
                <td>
                    <div className="pxp-dashboard-table-options">
                        <button type="button" className="btn btn-primary" title="Edit" data-bs-toggle="modal" data-bs-target="#configuracionPassModal" style={{ width: "auto", height: "auto" }}>Cambiar Contraseña</button>
                    </div>
                </td>
            </tr>

            <FormConfiguracionPassword data = {search} />
        </>
    )

}
import { useState } from "react";
import { helpHttp } from "../helpers/helpHttp";
import { useNavigate } from "react-router-dom";

let urlEndPoint = process.env.REACT_APP_API_URL;

export const useFormE2 = (initialForm, validateForm) => {
    const[form, setForm] = useState(initialForm);
    const[errors, setErros] = useState({});
    const[loading, setLoading] = useState(false);
    const[response, setResponse] = useState(null);

    const company = JSON.parse(localStorage.getItem('company'));
    const GetUserID = company.codeId;

    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm({
            ...form,
            [name]:value,
        });
    };
    const handleBlur = (e) => {
        handleChange(e);
        setErros(validateForm(form));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErros(validateForm(form));

        const{ idCompania,
            quienesSomos,
            nuestrosPilares,
            mision,
            vision,
        } = form;

        if(Object.keys(errors).length === 0){
            //alert("Formulario Enviado");

            let endPoint = `${urlEndPoint}/companias`;

            let optionsPars = {
                body: {
                    id: GetUserID,
                    quienesSomos : `${quienesSomos}`,
                    nuestrosPilares : `${nuestrosPilares}`,
                    mision : `${mision}`,
                    vision : `${vision}`
                }
            }

            console.log(optionsPars);

            try {

                helpHttp()
                .put(endPoint, optionsPars)
                .then( (res) => {
                    //console.log(res);
                    setLoading(false);
                    setResponse(true);
                    setTimeout(() => setResponse(false), 5000);
                    setForm(initialForm);
                })
                .catch(error => {
                    //this.setState({ errorMessage: error.toString() });
                    //console.log('There was an error!' + error);
                    console.error('There was an error!', error);
                });

            } catch (error) {
                //alert("error");
                console.log('There was an error!' + error);
                console.error(error);
            }
            
            redirecionar();

        } else {
            return
        }
    };

    const navigate = useNavigate();
    function redirecionar() {
        navigate("/");
    }
    

    return{
        form, 
        errors, 
        loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    }

};
import React, { useState }  from 'react';

//export default function CandidatoBarra({handleSearch}) {
const CandidatoBarra = ( {handleSearch} ) => {
    //if(!search) return null;

    const initialForm = {
        cg_nombre: "",
        cg_procesoseleccion: "",

    };
    
    const [form, setForm] = useState(initialForm);

    const handleChange = (e) => {
        setForm({
            ...form, [e.target.name]: e.target.value,
        })
    };

    const handleChangeb = (e) => {
        setForm({
            ...form, [e.target.name]: e.target.checked,
        })
        
        if(e.target.name == "jobTipoContrato" && e.target.checked === false){
            setForm({
                ...form, [e.target.name]: null,
            })
        }

        if(e.target.name == "jobTipoContrato" && e.target.checked === true){
            setForm({
                ...form, [e.target.name]: e.target.value,
            })
        }
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        //alert(e.target.cg_procesoseleccion[0].value)
        let arrayproceso = [];
        let arrayini = 1;

        e.target.querySelectorAll('[name="cg_procesoseleccion"]').forEach((e, i) => {

            if (e.checked === true) {
                //if (arrayini == 1){ arrayproceso = ""; } else { arrayproceso = arrayproceso + ","; }
                //console.log('Value of', i, 'th Item is:',  e.value);
                //arrayproceso = arrayproceso + e.value;
                arrayproceso.push(parseInt(e.value));
                arrayini ++;
            }
            
        });

        console.log('Total ' + arrayproceso);
        form.cg_procesoseleccion = arrayproceso
        
        handleSearch(form);
    }

    return (
        <>
        <form onSubmit={handleSubmit}>
            <div className="pxp-jobs-list-side-filter">
                <div className="pxp-list-side-filter-header d-flex d-lg-none">
                    <div className="pxp-list-side-filter-header-label">Filtro</div>
                    <a role="button"><span className="fa fa-sliders"></span></a>
                </div>
                <div className="mt-4 mt-lg-0 d-lg-block pxp-list-side-filter-panel">
                    <h3>Búsqueda por Nombre</h3>
                    <div className="mt-2 mt-lg-3">
                        <div className="input-group">
                            <span className="input-group-text"><span className="fa fa-search"></span></span>
                            <input type="text" name="cg_nombre" className="form-control" placeholder="Búsqueda por Nombre" onChange={handleChange} value={form.cg_nombre} autoComplete="off" style={{"border": "none"}} />
                        </div>
                    </div>

                    <h3 className="mt-3 mt-lg-4  ">Proceso</h3>
                    <div className="list-group mt-2 mt-lg-3  ">
                        <label className="list-group-item d-flex justify-content-between align-items-center pxp-checked">
                            <span className="d-flex">
                                <input className="form-check-input me-2" type="checkbox" value="1" name="cg_procesoseleccion" onChange={handleChange}  />
                                Recibido
                            </span>
                        </label>
                        <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                            <span className="d-flex">
                                <input className="form-check-input me-2" type="checkbox" value="2" name="cg_procesoseleccion" onChange={handleChange} />
                                Seleccionado
                            </span>
                        </label>
                        <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                            <span className="d-flex">
                                <input className="form-check-input me-2" type="checkbox" value="3" name="cg_procesoseleccion" onChange={handleChange} />
                                Finalista
                            </span>
                        </label>
                        <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                            <span className="d-flex">
                                <input className="form-check-input me-2" type="checkbox" value="4" name="cg_procesoseleccion" onChange={handleChange} />
                                Descartado
                            </span>
                        </label>
                    </div>

                    <h3 className="mt-3 mt-lg-4  d-lg-none">Visualización de CVs</h3>
                    <div className="list-group mt-2 mt-lg-3  d-lg-none">
                                    <label className="list-group-item d-flex justify-content-between align-items-center pxp-checked">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" />
                                            Vistos
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" />
                                            No Vistos
                                        </span>
                                        
                                    </label>
                    </div>

                    <h3 className="mt-3 mt-lg-4  d-lg-none">Edad</h3>
                    <div className="list-group mt-2 mt-lg-3  d-lg-none">
                                    <label className="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" />
                                            18-25
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3 pxp-checked">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" />
                                            26-30
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3 pxp-checked">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" />
                                            31-35
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" />
                                            36-40
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" />
                                            40 +
                                        </span>
                                        
                                    </label>
                    </div>
                </div>
                <div className="mt-4 w-100" style={{"textAlign":"center"}}>
                    <button type="submit" className='btn rounded-pill pxp-section-cta'>Aplicar</button>
                </div>
            </div>
        </form>    
        </>
    )

}

export default CandidatoBarra;
import React from 'react';
import { Link } from 'react-router-dom';
import Section1 from './sections/Section1';

export default function MuchasGracias() {
    return (
        <>
        <div className="pxp-dashboard-content-details">
            <Section1/>
        </div>
        </>
    );
}
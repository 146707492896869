import React from 'react';
import check from '../../../assets/images/check.svg'; 
import checkLight from '../../../assets/images/check-light.svg'; 
import img3 from '../../../assets/images/nosotros-03.png'; 
import img4 from '../../../assets/images/nosotros-04.png'; 
import img5 from '../../../assets/images/nosotros-05.png'; 

export default function Section1() {
    return (
        <div className="mt-4 mt-lg-5">
            <div class="pxp-container">
                <h1 class="pxp-section-h2 text-center">¡Ya estás listo!</h1>
                <p class="pxp-info-caption-cta text-center">Ya puedes seguir contactando nuevos talentos.</p>
                <div class="pxp-info-caption-cta text-center"><a href="#" class="btn rounded-pill pxp-section-cta">Ver candidatos<span class="fa fa-angle-right"></span></a></div>
                <h2 class="pxp-info-caption-cta pxp-section-h2 text-center">Síguenos también en nuestras redes sociales</h2>
                
                <div class="pxp-container">
                    <div class="pxp-footer-social mt-3 mt-lg-0">
                                <ul class="list-unstyled" style={{"padding-left": "38%"}}>
                                    <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                                    <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                                    <li><a href="#"><span class="fa fa-instagram"></span></a></li>
                                    <li><a href="#"><span class="fa fa-linkedin"></span></a></li>
                                </ul>
                            </div>
                </div>
            </div>
        </div>
    );
};
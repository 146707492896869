import React from 'react';
import { useFormUPW } from '../../hooks/useFormUPW';
import Message from '../messages/message';

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[.A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.passwordactual.trim()){
        errors.passwordactual = "El campo Contraseña Actual es requerido";
    } else if (form.passwordactual.trim() != form.passwordrecap.trim()){
        errors.passwordactual = "La contraseña actual no es la correcta";
    } else if (!form.password_new1.trim()){
        errors.password_new1 = "La campo Contraseña Nueva es requerida";
    } else if (!form.password_new2.trim()){
        errors.password_new2 = "La campo Repetir Contraseña es requerida";
    } else if (form.password_new1.trim() != form.password_new2.trim()){
        errors.password_newAll = "Las contraseñas son distintas";
    }

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}


const FormConfiguracionPassword = ({data}) => {
    
    const initialForm = {
        id: 1,
        passwordrecap: data.password,
        passwordactual: '',
        password_new1: '',
        password_new2: ''
    };


    const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormUPW(initialForm, validationsForms);
    

    return (
        <div className="modal fade" id="configuracionPassModal" tabIndex="-1" aria-labelledby="configuracionPassModalLabel" aria-hidden="true">
                <form onSubmit={handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="configuracionPassModalLabel">Cambiar Contraseña</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label className="col-form-label">Contraseña actual:</label>
                                            <input type="hidden" id="passwordrecap" name="passwordrecap" value={form.passwordrecap} required disabled />
                                            <input type="password" className="form-control" id="passwordactual" name="passwordactual" onBlur={handleBlur} onChange={handleChange} value={form.passwordactual} required />
                                            {errors.passwordactual && <p style={styles} > {errors.passwordactual}</p>}
                                        </div>
                                    </div>

                                    <br /><br />
                                    
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label className="col-form-label">Nueva contraseña:</label>
                                            <input type="password" className="form-control" id="password_new1" name="password_new1" onBlur={handleBlur} onChange={handleChange} value={form.password_new1} required />
                                            {errors.password_new1 && <p style={styles} > {errors.password_new1}</p>}
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="col-form-label">Repite tu contraseña:</label>
                                            <input type="password" className="form-control" id="password_new2" name="password_new2" onBlur={handleBlur} onChange={handleChange} value={form.password_new2} required />
                                            {errors.password_new2 && <p style={styles} > {errors.password_new2}</p>}
                                        </div>
                                        {errors.password_newAll && <p style={styles} > {errors.password_newAll}</p>}
                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
                { response && <Message msg="Se actualizo correctamente." bgColor="#198754" /> }
            </div>

            
    );

}

export default FormConfiguracionPassword;
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import MenuLeft from './components/menu-left/MenuLeft';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import HeaderExt from './components/Header-ext/HeaderExt';
import FooterExt from './components/footer-ext/FooterExt';

console.log (window.location.pathname);

const ubicacion = window.location.pathname;
var header = null;
var footer = null;
var menuleft = null;
var claseSubBody = null;


if (ubicacion == "/login"){
    header = <HeaderExt />
    footer = <FooterExt />
} else {
    menuleft = <MenuLeft />
    header = <Header />
    footer= <Footer />
    claseSubBody = "pxp-dashboard-content";
}

ReactDOM.render(
    <BrowserRouter>

        {menuleft}

        <div className={claseSubBody}>
    
            {header}
            <App />
            {footer}
    
        </div>
      
    </BrowserRouter>,
    document.querySelector('#root')
  );
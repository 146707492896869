import React from 'react';
import check from '../../../assets/images/check.svg'; 

import visa from '../../../assets/images/pago-visa.svg'; 
import mastercard from '../../../assets/images/pago-mastercard.svg'; 
import americanexpress from '../../../assets/images/pago-american-express.svg'; 
import diners from '../../../assets/images/pago-diners-club-international.svg'; 

export default function Section1() {
    return (
        <div className="mt-4 mt-lg-5">
            <div class="pxp-container">
                <div class="row">
                    <div class="col-xxl-8">
                        <h1>Pago de compra</h1>
                        <hr style={{ "height":"5px", "opacity": "1", "border":"0 none", "color":"#000", "background-color":"#000" }} />
                        <h3 className="mt-4 mt-lg-5">Ya casi estas por terminar</h3>
                        <hr />
                        <div class="row">
                            <div class="col-xxl-2"><img src={visa} style={{width: "100%"}} /></div>
                            <div class="col-xxl-2"><img src={mastercard} style={{width: "100%"}} /></div>
                            <div class="col-xxl-2"><img src={americanexpress} style={{width: "100%"}} /></div>
                            <div class="col-xxl-2"><img src={diners} style={{width: "100%"}} /></div>
                        </div>
                        <h3 className="mt-4 mt-lg-5">Detalle de la tarjeta </h3>
                        <hr />
                        <form>
                            <div class="row mt-4 mt-lg-5">
                                <div class="col-xxl-12">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-title" class="form-label">Nombre de la tarjeta de crédito</label>
                                        <input type="text" id="pxp-company-job-title" class="form-control" placeholder="Nombre de la tarjeta de crédito" />
                                    </div>
                                </div>
                                <div class="col-xxl-6">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-title" class="form-label">Número de la tarjeta de crédito</label>
                                        <input type="text" id="pxp-company-job-title" class="form-control" placeholder="Número de la tarjeta de crédito" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-xxl-2">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-category" class="form-label">Mes</label>
                                        <select id="pxp-company-job-category" class="form-select">
                                            <option>01</option>
                                            <option>02</option>
                                            <option>03</option>
                                            <option>04</option>
                                            <option>05</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xxl-2">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-category" class="form-label">Año</label>
                                        <select id="pxp-company-job-category" class="form-select">
                                            <option>2022</option>
                                            <option>2023</option>
                                            <option>2024</option>
                                            <option>2025</option>
                                            <option>2026</option>
                                            <option>2027</option>
                                            <option>2028</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xxl-2">
                                    <label for="pxp-company-job-location" class="form-label">CVV</label>
                                    <input type="text" id="pxp-company-job-location" class="form-control" placeholder="CVV" />
                                </div>
                            </div>
                        </form>

                        <h3 className="mt-4 mt-lg-5">Datos de Facturación </h3>
                        <hr />
                        <form>
                            <div class="row mt-4 mt-lg-5">
                                <div class="col-xxl-12">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-title" class="form-label">Nombre Completo</label>
                                        <input type="text" id="pxp-company-job-title" class="form-control" placeholder="Nombre Completo" />
                                    </div>
                                </div>
                                <div class="col-xxl-6">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-title" class="form-label">Número de identificación</label>
                                        <input type="text" id="pxp-company-job-title" class="form-control" placeholder="Número de identificación" />
                                    </div>
                                </div>
                                <div class="col-xxl-6">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-title" class="form-label">Teléfono</label>
                                        <input type="text" id="pxp-company-job-title" class="form-control" placeholder="Teléfono" />
                                    </div>
                                </div>
                                <div class="col-xxl-12">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-title" class="form-label">Dirección</label>
                                        <input type="text" id="pxp-company-job-title" class="form-control" placeholder="Dirección" />
                                    </div>
                                </div>
                                <div class="col-md-6 col-xxl-6">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-category" class="form-label">Departamento</label>
                                        <select id="pxp-company-job-category" class="form-select">
                                            <option>Lima</option>
                                            <option>Junín</option>
                                            <option>Libertad</option>
                                            <option>Ayacucho</option>
                                            <option>Cusco</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xxl-6">
                                    <div class="mb-3">
                                        <label for="pxp-company-job-category" class="form-label">Ciudad</label>
                                        <select id="pxp-company-job-category" class="form-select">
                                            <option>Lima</option>
                                            <option>SJL</option>
                                            <option>Miraflores</option>
                                            <option>La Molina</option>
                                            <option>Surco</option>
                                            <option>Los Olivos</option>
                                            <option>Breña</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6 col-xxl-12">
                                    <label for="pxp-company-job-location" class="form-label">Email</label>
                                    <input type="text" id="pxp-company-job-location" class="form-control" placeholder="Email" />
                                </div>
                            </div>
                        </form>

                        <div class="mt-4 mt-lg-5">
                                <a href="/muchas-gracias" class="btn rounded-pill pxp-section-cta">Pagar Ahora</a>
                        </div>
                    </div>

                    <div class="col-xxl-4">
                    <div class="pxp-plans-card-1">
                            <div class="pxp-plans-card-1-top">
                                <div class="pxp-plans-card-1-title">Resumen de la compra</div>
                                <hr />
                                <p>Los pagos con tarjetas de crédito pueden tardar hasta 24 horas en ser procesadas.</p>
                                <p>Al dar clic en comprar "Pagar ahora", estás de acuerdo con nuestros términos y condiciones y el almacenamiento de tu información en nuestra base de datos.</p>
                                <div class="pxp-plans-card-1-title">Plan Básico</div>
                                <hr />
                                <div class="pxp-plans-card-1-price">
                                    <div class="pxp-plans-price-monthly">
                                        S/40<span class="pxp-period">/ 60 créditos</span>
                                    </div>
                                </div>
                                <div class="pxp-plans-card-1-list">
                                    <ul class="list-unstyled">
                                        <li><img src={check} alt="" />Publica ilimitadamente ofertas laborales sin caducidad</li>
                                        <li><img src={check} alt="" />Hunting de talentos</li>
                                        <li><img src={check} alt="" />Soporte exclusivo</li>
                                        <li><img src={check} alt="" />60 créditos</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};
import React from 'react';
import phSmall from '../../assets/images/ph-small.jpg';

{/* Texto  */}
export default function CandidatosList({candidatos}) {
    
    let url = "/ver-cv-postulante/";

    return (
        <>
                {
                    candidatos.map(({id, nombre, apellidoPaterno, apellidoMaterno, direccion, sobremiCargo},index)=>{
                        return <div className="col-md-6 col-lg-12 col-xl-6 col-xxl-4 pxp-candiadates-card-1-container" key={index}>
                        <div className="pxp-candiadates-card-1 pxp-has-border text-center">
                            <div className="pxp-candiadates-card-1-top">
                                <div className="pxp-candiadates-card-1-avatar pxp-cover" style={{backgroundImage: `url(${phSmall})`}}></div>
                                <div className="pxp-candiadates-card-1-name">{nombre} {apellidoPaterno}</div>
                                <div className="pxp-candiadates-card-1-title">{sobremiCargo}</div>
                                <div className="pxp-candiadates-card-1-location"><span className="fa fa-globe"></span>{direccion}</div>
                            </div>
                            <div className="pxp-candiadates-card-1-bottom"  >
                                <div className="pxp-candiadates-card-1-cta">
                                    <a href={url+id} >Ver perfil<span className="fa fa-angle-right"></span></a>
                                </div>
                            </div>
                        </div>
                    </div>;
                    })
                }
        </>
    );
};
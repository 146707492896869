import React from 'react';
import CandidatosList from '../../../components/candidatos/CandidatosList';
import CandidatosSearchTop from '../../../components/candidatos/CandidatosSearchTop';
import CandidatosSearchBottom from '../../../components/candidatos/CandidatosSearchBottom';
export default function BuscarCandidatoLista({search, candList}) {

    //if(!search) return null;
    console.log("LISTAs - PREV");
    if(!candList) return null;
    console.log("LISTAs - COMP");

    return (
        <>

            { /*<CandidatosSearchTop />*/ }

            <div className="row">
                <CandidatosList candidatos={candList} />                     
            </div>

            { /*<CandidatosSearchBottom /> */ }
            
        </>
    )

}
export const helpHttp = () => {
    const customFetch = (endpoint, options) => {
        const defaultHeader = {
            //accept: "application/json",
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8'
        };

        const controller = new AbortController();
        //var signal = controller.signal;
        options.signal = controller.signal;

        //signal.aborted ? console.log(`Request aborted with reason: ${signal.reason}`) : console.log('Request not aborted');


        options.method = options.method || "GET";

        options.headers = options.headers ? { ...defaultHeader, ...options.headers } : defaultHeader;

        options.body = JSON.stringify(options.body) || false;

        if(!options.body) delete options.body;

        console.log(options);

        setTimeout(() => controller.abort(), 15000);

        return fetch(endpoint, options).then(
            res => res.ok ? res.json() : Promise.reject({
                err: true,
                status: res.status || "00",
                statusText: res.statusText || "Ocurrió un error"
            })
        ).catch(
            (err) => err
        );

    }

    const get = (url, options = {} ) => customFetch(url, options);

    const post = (url, options = {} ) => {
        options.method = "POST";
        return customFetch(url, options);
    }

    const patch = (url, options = {} ) => {
        options.method = "PATCH";
        return customFetch(url, options);
    }

    const put = (url, options = {} ) => {
        options.method = "PUT";
        return customFetch(url, options);
    }

    const del = (url, options = {} ) => {
        options.method = "DELETE";
        return customFetch(url, options);
    }


    return {
        get, post, patch, put, del
    };

};

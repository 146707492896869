import React from 'react';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';

export default function CvVerPostulanteDerecho({ experiencia, foracademica, conocimientos}) {

    if(!experiencia) return null;
    if(!foracademica) return null;
    if(!conocimientos) return null;

    return (
        <>
            <div className="row">
                <div className="mt-2 ">
                    <h2>Experiencia Laboral</h2>
                    {
                        experiencia.map(({nombre, compania, descripcion, fechaIngreso, fechaTermino},index)=>{
                        return <div className="table-responsive">
                            <table className="table table-hover align-middle">
                                <tbody>
                                    <tr>
                                        <td style={{width: "50%" }} ><div className="pxp-company-dashboard-subscriptions-plan">{nombre}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="pxp-company-dashboard-job-date">{compania}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="pxp-company-dashboard-job-date">{fechaIngreso.substring(0,4)} - {fechaTermino.substring(0,4)}</div></td>
                                    </tr>
                                    <tr className='d-lg-none'>
                                        <td><div className="pxp-company-dashboard-job-date d-lg-none">+ 1 año y 9 meses</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="pxp-company-dashboard-job-date">{descripcion}</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        ;
                        })
                    }
                </div>      
            </div>


            <div className="row">     
                <div className="mt-4 mt-lg-5">
                    <h2>Formación Académica</h2>
                    {
                        foracademica.map(({id, areaProfesional, centroEducativo, nivelEstudios, fechaInicio, fechaTermino},index)=>{
                        return <div className="table-responsive">
                                    <table className="table table-hover align-middle">
                                        <tbody>
                                            <tr>
                                                <td style={{width: "50%" }} ><div className="pxp-company-dashboard-subscriptions-plan">{areaProfesional}</div></td>
                                            </tr>
                                            <tr>
                                                <td><div className="pxp-company-dashboard-job-date">{centroEducativo}</div></td>
                                            </tr>
                                            <tr>
                                                <td><div className="pxp-company-dashboard-job-date">{fechaInicio.substring(0,4)} - {fechaTermino.substring(0,4)}</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                ;
                            })
                        }

                </div>       
            </div>
            <div className="row">
                <div className="mt-4 mt-lg-5">
                    <h2>Habilidades Profesionales</h2>
                        <div className="pxp-candidate-dashboard-skills mb-3">
                            <ul className="list-unstyled">
                                {
                                conocimientos.map(({id, nombre},index)=>{
                                return<li key={index}>{nombre}</li>
                                                ;
                                    })
                                }
                            </ul>
                        </div>
                </div>
            </div>


                    
        </>
    )
}
import React,{useState, useEffect} from 'react';
import { findByParametersCandidatos, findListaCandidatos } from '../../../services/apis';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import BuscarCandidatoBarra from './BuscarCandidatoBarra';
import BuscarCandidatoLista from './BuscarCandidatoLista';

export default function Section1() {

    const [search, setSearch] = useState(null);

    const [candList, setCandList] = useState(null);
    const [jobDetail, setJobDetail] = useState(null);
    const [loading, setLoading] = useState(false);

    /*useEffect( () => {

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                const res = await findListaCandidatos();
                console.log(res);
                setSearch(res);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);*/

    const getPuesto = null;
    const getUbicacion = null;
    const getCompania = 1;

    useEffect( async () => {

        window.scrollTo(0, 0); // FORZA TOP

        //if ( (search === null) && (getPuesto === null && getUbicacion === null) ) return;
        //if (search === null) return;

        const jobTipoA = 1;
        const jobModalidadB = 1;
        const companiaID = 1;

        if(search){ //(getPuesto.length === 0 && getUbicacion.length === 0)
            const{candsearch, candDni, candnacionalidad, candNivelEstudios} = search;
            console.log("CANDIDATO NOMBRE: " + candsearch);
            console.log("USE JOBSEARCH A");
            
            const respList = await findByParametersCandidatos(candsearch, candDni, candnacionalidad, candNivelEstudios);
            console.log(respList);
            setCandList(respList);
            //setSearch(jobsList);

        } else if( (getPuesto || getUbicacion) ){
            console.log("USE JOBSEARCH B");

            const respList = await findByParametersCandidatos(getPuesto, getUbicacion, jobTipoA, jobModalidadB, companiaID);
            setCandList(respList);
            //setSearch(respList);

        } else if( getCompania ){
            console.log("USE JOBSEARCH C");

            const respList = await findByParametersCandidatos("", "", "", "", 1);
            console.log(respList);
            
            setCandList(respList);
            //setSearch(respList);
        } else {
            console.log("USE JOBSEARCH D");
        }

    },[search]);
    

    const handleSearch = (data) => {
        setSearch(data);
    }


    return (
        <section >
            <div className="pxp-container">
                <div className="row">
                    <div className="col-lg-5 col-xl-4 col-xxl-3">
                        <BuscarCandidatoBarra handleSearch = {handleSearch} />
                    </div>
                    
                    <div className="col-lg-7 col-xl-8 col-xxl-9">
                        <BuscarCandidatoLista search ={search} candList = {candList} />
                    </div>
                </div>
            </div>
        </section>
    )
}
import React, { useState }  from 'react';

//export default function AvisoBarra() {
const AvisoBarra = ( {handleSearch, puesto, ubicacion, compania} ) => {

        const initialForm = {
            jobsearch: "",
            joblocation: "",
            jobDestacado: "",
            jobUrgente: "",
            jobTipoContrato: "",
        };
        
        const [form, setForm] = useState(initialForm);
    
        const handleChange = (e) => {
            //alert(e.target.name);
            setForm({
                ...form, [e.target.name]: e.target.value,
            })

            
        };

        const handleChangeb = (e) => {
            setForm({
                ...form, [e.target.name]: e.target.checked,
            })
            
            if(e.target.name == "jobTipoContrato" && e.target.checked === false){
                //form.jobTipoContrato = null;
                //console.log(form.jobTipoContrato);
                setForm({
                    ...form, [e.target.name]: null,
                })
            }

            if(e.target.name == "jobTipoContrato" && e.target.checked === true){
                //form.jobTipoContrato = e.target.value;
                //console.log(form.jobTipoContrato);
                setForm({
                    ...form, [e.target.name]: e.target.value,
                })
            }
            
        };
    
        const handleSubmit = (e) => {
            e.preventDefault();
    
            /*if(!form.jobsearch || !form.joblocation){
                alert("Datos incompletos");
                return;
            }*/
    
            handleSearch(form);
            //setForm(initialForm);
        }

    //if(!search) return null;

    return (
        <>  
            <form onSubmit={handleSubmit}>
                        <div className="pxp-jobs-list-side-filter">
                            <div className="pxp-list-side-filter-header d-flex d-lg-none">
                                <div className="pxp-list-side-filter-header-label">Filtros</div>
                                <a role="button"><span className="fa fa-sliders"></span></a>
                            </div>
                            <div className="mt-4 mt-lg-0 d-lg-block pxp-list-side-filter-panel">
                                <h3>Buscar Aviso</h3>
                                <div className="mt-2 mt-lg-3">
                                    <div className="input-group">
                                        <span className="input-group-text"><span className="fa fa-search"></span></span>
                                        <input type="text" name="jobsearch" className="form-control" placeholder="Título de aviso" onChange={handleChange} value={form.jobsearch} autoComplete="off" style={{"border": "none"}} />
                                    </div>
                                </div>

                                <h3 className="mt-3 mt-lg-4">Tipo de contrato</h3>
                                <div className="list-group mt-2 mt-lg-3">
                                    <label className="list-group-item d-flex justify-content-between align-items-center pxp-checked">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" name="jobTipoContrato" value="1" onChange={handleChangeb} />
                                            Jornada completa
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3 pxp-checked">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" name="jobTipoContrato" value="2" onChange={handleChangeb} />
                                            Media jornada
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" name="jobTipoContrato" value="3" onChange={handleChangeb} />
                                            Contrato por obra
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" name="jobTipoContrato" value="4" onChange={handleChangeb} />
                                            Temporal
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" name="jobTipoContrato" value="5" onChange={handleChangeb} />
                                            Voluntario
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" name="jobTipoContrato" value="6" onChange={handleChangeb} />
                                            Prácticas
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" name="jobTipoContrato" value="7" onChange={handleChangeb} />
                                            Otros
                                        </span>
                                        
                                    </label>
                                </div>

                                <h3 className="mt-3 mt-lg-4">Características</h3>
                                <div className="list-group mt-2 mt-lg-3">
                                    <label className="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" name="jobDestacado" onChange={handleChangeb} value="1" />
                                            Destacados
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3 pxp-checked">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" name="jobUrgente" onChange={handleChangeb} value="1" />
                                            Urgentes
                                        </span>
                                        
                                    </label>
                                </div>

                            </div>
                            <div className="mt-4 w-100" style={{"textAlign":"center"}}>
                                <button type="submit" className='btn rounded-pill pxp-section-cta'>Aplicar</button>
                            </div>
                        </div>
                        
            </form>
        </>
    )

}

export default AvisoBarra;
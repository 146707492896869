import React from 'react';
import FormConfiguracionEmail from '../../../components/conf-email/FormConfiguracionEmail';

export default function ConfiguracionEmail({search}) {

    if(!search) return null;

    return (
        <>
            <tr>
                <td style={{width: "80%"}}>
                    <div className="pxp-candidate-dashboard-experience-title">Email</div>
                    <div className="pxp-candidate-dashboard-experience-school">Estás registrado con el e-mail: <b>{search.mail}</b></div>
                    <br />
                    <p className="pxp-company-dashboard-job-date d-none">A esta dirección te enviaremos las notificaciones sobre el estado de tus avisos y productos.</p>
                </td>
                <td>
                    <div className="pxp-dashboard-table-options">
                        <button type="button" className="btn btn-primary" title="Cambiar Email" data-bs-toggle="modal" data-bs-target="#configuracionEmailModal" style={{ width: "auto", height: "auto" }}>Cambiar Email</button>
                    </div>
                </td>
            </tr>

            <FormConfiguracionEmail data = {search} />
        </>
    )

}
import React from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import phSmall from '../../assets/images/ph-small.jpg';
import logo from '../../assets/images/Tconvoco-logo.png';
import { useCookies } from 'react-cookie';

let urlDomain = process.env.REACT_APP_URL_DOMAIN;
let urlPublico = process.env.REACT_APP_URL_PUBLIC;
const activeClass = (route) => { return window.location.pathname === route ? "pxp-active" : null }

export default function MenuLeft() {

    const user = JSON.parse(localStorage.getItem('company'));
    const [cookies, setCookie, removeCookie] = useCookies(['accessTokenCompany', 'company']);

    const handleLogout = () => {

        localStorage.removeItem("accessTokenCompany");
        localStorage.removeItem("company");

        localStorage.clear();
        sessionStorage.clear();

        removeCookie('accessTokenCompany',{ path:'/', domain: urlDomain } );
        removeCookie('company',{ path:'/', domain: urlDomain });

        //window.location.href = "/login";
        window.location.href = urlPublico;
    };

    return (
        <div className="pxp-dashboard-side-panel d-none d-lg-block">
            <div className="pxp-logo " >
                <a href={urlPublico} className="pxp-animate"><img src={logo} alt="-" style={{ maxWidth: "80%" }} /></a>
            </div>

            <nav className="mt-3 mt-lg-4 d-flex justify-content-between flex-column pb-100">
                <ul className="list-unstyled">
                    <li className={activeClass("/")} ><a href="/"><span className="fa fa-home"></span>Inicio</a></li>
                    <li className={activeClass("/mis-avisos")} ><a href="/mis-avisos"><span className="fa fa-briefcase"></span>Mis Avisos</a></li>
                    <li className={activeClass("/publicar-aviso")} ><a href="/publicar-aviso"><span className="fa fa-file-text-o"></span>Publicar un anuncio</a></li>
                    <li className={activeClass("/buscar-candidato")} ><a href="/buscar-candidato"><span className="fa fa-users"></span>Buscar candidatos</a></li>

                    <li className={activeClass("/empresa")} ><a href="/empresa"><span className="fa fa-building"></span>Sobre la empresa</a></li>
                    <li className={activeClass("/configuracion")} ><a href="/configuracion"><span className="fa fa-lock"></span>Configuración</a></li>
                    
                    <li className={activeClass("/planes")} ><a href="/planes"><span className="fa fa-credit-card"></span>Planes</a></li>
                    
                </ul>
                
            </nav>

            <nav className="pxp-dashboard-side-user-nav-container">
                <div className="pxp-dashboard-side-user-nav">
                    <div className="dropdown pxp-dashboard-side-user-nav-dropdown dropup">
                        <a role="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                            <div className="pxp-dashboard-side-user-nav-avatar pxp-cover" style={{backgroundImage: `url(${phSmall})`}}></div>
                            <div className="pxp-dashboard-side-user-nav-name">Empresa</div>
                        </a>
                        <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="/mis-avisos">Mis Avisos</a></li>
                            <li><a className="dropdown-item" href="/empresa">Empresa</a></li>
                            <li><a className="dropdown-item" onClick={handleLogout} >Salir</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}
import React from 'react';

export default function ConfiguracionPrivacidad() {

    //if(!search) return null;

    return (
        <>
            <tr>
                <td style={{width: "80%"}}>
                    <div className="pxp-candidate-dashboard-experience-title">Privacidad</div>
                </td>
                <td>
                    <div className="pxp-dashboard-table-options">
                        <a href='/privacidad'><button type="button" className="btn btn-primary" title="Ver" style={{ width: "auto", height: "auto" }}>Ver Privacidad</button></a>
                    </div>
                </td>
            </tr>
        </>
    )

}
import { helpHttp } from "../helpers/helpHttp";

let urlEndPoint = process.env.REACT_APP_API_URL;

export async function findEmpleoByID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/empleos/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findEmpleoByIDandEmpresaID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/empleos/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function deleteEmpleoByID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/empleos/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().del(busquedaURLPars);

    return result;

    /*try {
        
        const responseFetch = await fetch(`${urlEndPoint}/postulaciones/${credentials}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        return responseFetch;

    } catch (error) {
        console.error(error);
    }*/

}

export async function findListaEmpleosByEmpresa ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/empleos/empresa/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findCandidatosByEmpresa ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/postulaciones/compania/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findListaCandidatos (  ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/postulantes/`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/postulantes/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findEmpresaByRUC ( ruc ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/companias/nroDoc/${ruc}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findEmpresaByID ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/companias/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findPaises (){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/paises/`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findTipoContrato (){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/paises/`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findDepartamentos (id){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/departamentos/${id}/departamentos`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findPronvicia (id){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/provincias/${id}/provincias`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findDistrito (id){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/provincias/${id}/distritos`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findListaEmpleosByCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/postulaciones/empleo/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findListaEmpleosByCandidatoFiltro ( id, data, nombre ){

    let busquedaURLPars;
    let result;
    let filtro;
    let nombrePostulante;
    console.log("DATA FILTRO API: " + data)

    filtro = data.length > 0 ? data : null;
    nombrePostulante = nombre ? nombre : null;

    busquedaURLPars = `${urlEndPoint}/postulaciones/empleofiltro/`;

    let optionsPars = {
        body: {
            "empleoId" : {
                "id" : `${id}`
            },
            "postulanteEstadoTxtArray" : filtro,
            "postulanteNombreTxt" : nombrePostulante
        }
    }

    console.log(busquedaURLPars);
    result = await helpHttp().post(busquedaURLPars, optionsPars);

    return result;

}


export async function findByParameters ( puesto, ubicacion, tipoDestacado, tipoUrgente, companiaID, tipoContrato ){

    let busquedaURLPars = `${urlEndPoint}/empleos/-/find`;
    let optionsBody;

    if (tipoDestacado === false) { tipoDestacado = "" }
    if (tipoUrgente === false) { tipoUrgente = "" }

    //console.log("PUESTO " + puesto);
    
    if( tipoContrato ){
        /*optionsBody = {
            companiaId: companiaID
        }*/
        optionsBody = {
            nombre : `${puesto}`,
            //provincia : `${ubicacion}`,
            destacada : `${tipoDestacado}`,
            urgente : `${tipoUrgente}`,
            tipoEmpleoEnum: {
                id : `${tipoContrato}`
            },
            //modalidadEmpleoEnum: {
            //    id : `${modalidadEmpleo}`
            //},
            companiaId: companiaID
        }
        
    } else {
        optionsBody = {
            nombre : `${puesto}`,
            //provincia : `${ubicacion}`,
            destacada : `${tipoDestacado}`,
            urgente : `${tipoUrgente}`,
            /*tipoEmpleoEnum: {
                id : `${tipoContrato}`
            },*/
            //modalidadEmpleoEnum: {
            //    id : `${modalidadEmpleo}`
            //},
            companiaId: companiaID
        }
    }

    let optionsPars = {
        headers: { type: 'normal' },
        body: optionsBody
    }

    console.log("CONSULTA SERVICIOS - JOBS")
    console.log(busquedaURLPars);
    const [busquedaResPar] = await Promise.all([helpHttp().patch(busquedaURLPars, optionsPars)]);

    return busquedaResPar;

}


export async function findByParametersCandidatos ( nombrePuesto, candDni, nacionalidad, nivelEstudios, companiaID, tipoContrato ){

    let busquedaURLPars = `${urlEndPoint}/postulantes/-/find`;
    let optionsBody;

    //if (tipoDestacado === false) { tipoDestacado = "" }
    //if (tipoUrgente === false) { tipoUrgente = "" }

    //console.log("PUESTO " + puesto);
    
    if( nombrePuesto || candDni ){
        /*optionsBody = {
            companiaId: companiaID
        }*/
        optionsBody = {
            nombre : `${nombrePuesto}`,
            dni : `${candDni}`,
            /*destacada : `${tipoDestacado}`,
            urgente : `${tipoUrgente}`,
            tipoEmpleoEnum: {
                id : `${tipoContrato}`
            },*/
            //modalidadEmpleoEnum: {
            //    id : `${modalidadEmpleo}`
            //},
            //companiaId: companiaID
        }
        
    } else {
        optionsBody = {
            nombre : `${nombrePuesto}`,
            //provincia : `${ubicacion}`,
            //destacada : `${tipoDestacado}`,
            //urgente : `${tipoUrgente}`,
            /*tipoEmpleoEnum: {
                id : `${tipoContrato}`
            },*/
            //modalidadEmpleoEnum: {
            //    id : `${modalidadEmpleo}`
            //},
            //companiaId: companiaID
        }
    }

    let optionsPars = {
        headers: { type: 'normal' },
        body: optionsBody
    }

    console.log("CONSULTA SERVICIOS - JOBS")
    console.log(busquedaURLPars);
    const [busquedaResPar] = await Promise.all([helpHttp().patch(busquedaURLPars, optionsPars)]);

    return busquedaResPar;

}


export async function findCandidatoExperienciaLaboral ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/experiencialaboral/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findFormacionAcademicaByIDCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/formacionacademica/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findConocimientoByIDCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/conocimientoshabilidades/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}

export async function findOtrosConocimientosByIDCandidato ( id ){

    let busquedaURLPars;
    let result;

    busquedaURLPars = `${urlEndPoint}/otrosconocimientos/candidato/${id}`;

    console.log(busquedaURLPars);
    result = await helpHttp().get(busquedaURLPars);

    return result;

}
import React, { useState }  from 'react';

//export default function BuscarCandidatoBarra() {
const BuscarCandidatoBarra = ( {handleSearch} ) => {
    //if(!search) return null;

    const initialForm = {
        candsearch: "",
        candDni: "",
        candnacionalidad: "",
        candNivelEstudios: "",
        candDestacado: "",
        candUrgente: "",
        candTipoContrato: "",
    };
    
    const [form, setForm] = useState(initialForm);

    const handleChange = (e) => {
        setForm({
            ...form, [e.target.name]: e.target.value,
        })
    };

    const handleChangeb = (e) => {
        setForm({
            ...form, [e.target.name]: e.target.checked,
        })
        
        if(e.target.name == "jobTipoContrato" && e.target.checked === false){
            setForm({
                ...form, [e.target.name]: null,
            })
        }

        if(e.target.name == "jobTipoContrato" && e.target.checked === true){
            setForm({
                ...form, [e.target.name]: e.target.value,
            })
        }
        
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSearch(form);
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="pxp-jobs-list-side-filter">
                    <div className="pxp-list-side-filter-header d-flex d-lg-none">
                        <div className="pxp-list-side-filter-header-label">Filtro</div>
                        <a role="button"><span className="fa fa-sliders"></span></a>
                    </div>
                    
                    <div className="mt-4 mt-lg-0 d-lg-block pxp-list-side-filter-panel">
                        <h3 className="mt-3 " >Nombre</h3>
                        <div className="mt-2 mt-lg-3">
                            <div className="input-group">
                                <span className="input-group-text"><span className="fa fa-search"></span></span>
                                <input type="text" name="candsearch" className="form-control" placeholder="Buscar por nombre" onChange={handleChange} value={form.candsearch} autoComplete="off" style={{"border": "none"}} />
                            </div>
                        </div>

                        <h3 className="mt-3 " >Nro. DNI</h3>
                        <div className="mt-2 mt-lg-3">
                            <div className="input-group">
                                <span className="input-group-text"><span className="fa fa-search"></span></span>
                                <input type="text" name="candDni" className="form-control" placeholder="Buscar por DNI" onChange={handleChange} value={form.candDni} autoComplete="off" style={{"border": "none"}} />
                            </div>
                        </div>

                                <h3 className="mt-3 d-lg-none" >Ubicación</h3>
                                <div className="mt-2 mt-lg-3 d-lg-none">
                                    <div className="input-group">
                                        <span className="input-group-text"><span className="fa fa-globe"></span></span>
                                        <input type="text" className="form-control" placeholder="Ubicación" style={{"border": "none"}} />
                                    </div>
                                </div>

                                <h3 className="mt-3 d-lg-none">Nacionalidad</h3>
                                <div className="mt-2 mt-lg-3 d-lg-none">
                                    <div className="input-group">
                                        <span className="input-group-text"><span className="fa fa-folder-o"></span></span>
                                        <select class="form-select" aria-label="Default select example" style={{"border": "none"}} name="candnacionalidad" defaultValue="0" onChange={handleChange} >
                                            <option value="0">Seleccionar</option>
                                            <option value="1">Perú</option>
                                            <option value="2">Brasil</option>
                                        </select>
                                    </div>
                                </div>

                                <h3 className="mt-3 mt-lg-4 d-lg-none">Experiencia</h3>
                                <div className="list-group mt-2 mt-lg-3 d-lg-none">
                                    <label className="list-group-item d-flex justify-content-between align-items-center pxp-checked">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" checked />
                                            0 - 1 años
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" />
                                            2 - 3 años
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                            <input className="form-check-input me-2" type="checkbox" value="" />
                                            4 - 6 años
                                        </span>
                                        
                                    </label>
                                </div>

                                <h3 className="mt-3 mt-lg-4 d-lg-none">Nivel de estudios</h3>
                                <div className="list-group mt-2 mt-lg-3 d-lg-none">
                                    <label className="list-group-item d-flex justify-content-between align-items-center">
                                        <span className="d-flex">
                                        <input className="form-check-input me-2" type="checkbox" name="candNivelEstudios" value="1" onChange={handleChangeb} />
                                            Secundaria completa
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3 pxp-checked">
                                        <span className="d-flex">
                                        <input className="form-check-input me-2" type="checkbox" name="candNivelEstudios" value="2" onChange={handleChangeb} />
                                            Técnico
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3 pxp-checked">
                                        <span className="d-flex">
                                        <input className="form-check-input me-2" type="checkbox" name="candNivelEstudios" value="3" onChange={handleChangeb} />
                                            Universitario
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                        <input className="form-check-input me-2" type="checkbox" name="candNivelEstudios" value="4" onChange={handleChangeb} />
                                            Doctorado
                                        </span>
                                        
                                    </label>
                                    <label className="list-group-item d-flex justify-content-between align-items-center mt-2 mt-lg-3">
                                        <span className="d-flex">
                                        <input className="form-check-input me-2" type="checkbox" name="candNivelEstudios" value="5" onChange={handleChangeb} />
                                            Magister
                                        </span>
                                        
                                    </label>
                                </div>                                

                            </div>
                            <div class="mt-4 w-100" style={{"text-align":"center"}}>
                            <button type="submit" className='btn rounded-pill pxp-section-cta'>Aplicar</button>
                            </div>
                </div>
            </form>
        </>
    )

}

export default BuscarCandidatoBarra;
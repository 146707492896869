import React from 'react';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import GestionCandidatosList from '../../../components/gestion-candidatos/GestionCandidatosList';
import GestionCandidatosSearchTop from '../../../components/gestion-candidatos/GestionCandidatosSearchTop';
import GestionCandidatosSearchBottom from '../../../components/gestion-candidatos/GestionCandidatosSearchBottom';
export default function CandidatoLista({search}) {

    if(!search) return null;

    return (
        <>

            { /*<GestionCandidatosSearchTop />*/ }
            
            <div>

                <GestionCandidatosList candidatos={search} />

            </div>
            
            { /*<GestionCandidatosSearchBottom />*/ }
            
        </>
    )

}
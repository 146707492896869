import React, { useState }  from 'react';
import phSmall from '../../assets/images/ph-small.jpg';

{/* Texto  */}
export default function GestionCandidatosList({candidatos}) {
    let url = "/cv-postulante/";
    var idPostulacionBase64 = "";
    var idPostulacionStatusBase64 = "";

    function routeChange(idPostulante, idPostulacion, status) {

        idPostulacionBase64 = Buffer.from(idPostulacion.toString()).toString('base64');
        localStorage.setItem('postula', idPostulacionBase64 );

        idPostulacionStatusBase64 = Buffer.from(status.toString()).toString('base64');
        localStorage.setItem('postulastatus', idPostulacionStatusBase64 );
        
        let path = url+idPostulante;
        console.log(path);
        //navigate(path);
        window.location.href = path;

        /*window.open(
            path,
            '_blank'
        );*/
    }

    let estadoPostulancionTxt;

    const estadoPostulancion = [
        {idst: 1, labeltxt:'Recibido'},
        {idst: 2, labeltxt:'Seleccionado'},
        {idst: 3, labeltxt:'Finalista'},
        {idst: 4, labeltxt:'Descartado'},
        {idst: 5, labeltxt:'Otros'}
    ];

    console.log(estadoPostulancion);
    let colorstado;

    return (
        <>
                {
                    candidatos.map(({id, postulanteId, postulanteEstado},index)=>{

                        /*estadoPostulancion.map(({idst,labeltxt}, index) => {
                            estadoPostulancionTxt = (idst == postulanteEstado) ? postulanteEstado : ""
                        })*/
                        const found = estadoPostulancion?.find(obj => {
                            return obj.idst === postulanteEstado;
                        });
                        
                        //console.log("FOUND "+found)
                        if (postulanteEstado == 1) {
                            colorstado = 'badge rounded-pill bg-warning'
                        } else if(postulanteEstado == 2){
                            colorstado = 'badge rounded-pill bg-primary'
                        } else if(postulanteEstado == 3){
                            colorstado = 'badge rounded-pill bg-success'
                        } else if(postulanteEstado == 4){
                            colorstado = 'badge rounded-pill bg-danger'
                        } else {
                            colorstado = 'badge rounded-pill bg-secondary'
                        }


                        estadoPostulancionTxt = <span className={colorstado}>&nbsp; {found.labeltxt} &nbsp;</span>
                        
                        
                        return <div className="pxp-jobs-card-3 pxp-has-border" key={index}>
                            <div className="row align-items-center justify-content-between">
                                <div className="col-sm-3 col-md-2 col-lg-3 col-xl-2 col-xxl-auto">
                                    <a href="javascript: void(0)" onClick={() => routeChange(postulanteId.id, id, postulanteEstado)} className="pxp-jobs-card-3-company-logo" style={{backgroundImage: `url(${phSmall})`}}></a>
                                </div>
                                <div className="col-sm-9 col-md-10 col-lg-9 col-xl-10 col-xxl-4">
                                    <a href="javascript: void(0)" onClick={() => routeChange(postulanteId.id, id, postulanteEstado)} className="pxp-jobs-card-3-title mt-3 mt-sm-0">{postulanteId.nombre} {postulanteId.apellidoPaterno} {postulanteId.apellidoMaterno}</a>
                                    <div className="pxp-jobs-card-3-details">
                                        <a href="javascript: void(0)" onClick={() => routeChange(postulanteId.id, id, postulanteEstado)} className="pxp-jobs-card-3-location">
                                            <span className="fa fa-globe"></span>{postulanteId.mail}
                                        </a>
                                        <div className="pxp-jobs-card-3-type" style={{"display":"none"}}>Full-time</div>
                                    </div>
                                </div>
                                <div className="col-sm-8 col-xl-6 col-xxl-4 mt-3 mt-xxl-0">
                                    <a href="javascript: void(0)" onClick={() => routeChange(postulanteId.id, id, postulanteEstado)} className="pxp-jobs-card-3-category">
                                        <div className="pxp-jobs-card-3-category-label">{postulanteId.sobremiCargo}</div>
                                    </a>
                                    <div className="pxp-jobs-card-3-date-company" >
                                        {estadoPostulancionTxt}
                                        <span className="pxp-jobs-card-3-date pxp-text-light d-none">{estadoPostulancionTxt}</span>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-xl-2 col-xxl-auto mt-3 mt-xxl-0 pxp-text-right">
                                    <div className="pxp-dashboard-table-options">
                                        <ul className="list-unstyled">
                                            <li><button title="Ver candidato" onClick={() => routeChange(postulanteId.id, id, postulanteEstado)} ><span className="fa fa-eye"></span></button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>;
                    })
                }

                        {/*<div>
                            <div className="pxp-jobs-card-3 pxp-has-border">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-sm-3 col-md-2 col-lg-3 col-xl-2 col-xxl-auto">
                                        <a href="/cv-postulante" className="pxp-jobs-card-3-company-logo" style={{backgroundImage: `url(${phSmall})`}}></a>
                                    </div>
                                    <div className="col-sm-9 col-md-10 col-lg-9 col-xl-10 col-xxl-4">
                                        <a href="/cv-postulante" className="pxp-jobs-card-3-title mt-3 mt-sm-0">Leonid Laura </a>
                                    </div>
                                    <div className="col-sm-8 col-xl-6 col-xxl-auto mt-3 mt-xxl-0">
                                        <a href="/cv-postulante" className="pxp-jobs-card-3-category">
                                            <div className="pxp-jobs-card-3-category-label">Hoy</div>
                                        </a>
                                    </div>
                                    <div className="col-sm-8 col-xl-6 col-xxl-2 mt-3 mt-xxl-0">
                                        <a href="/cv-postulante" className="pxp-jobs-card-3-category">
                                            <div className="pxp-jobs-card-3-category-label">Universitario</div>
                                        </a>
                                    </div>
                                    <div className="col-sm-4 col-xl-2 col-xxl-auto mt-3 mt-xxl-0 pxp-text-right">
                                        <div class="pxp-dashboard-table-options">
                                            <ul class="list-unstyled">
                                                <li><button title="Editar"><span class="fa fa-pencil"></span></button></li>
                                                <li><button title="Descargar"><span class="fa fa-file-text"></span></button></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>*/}
        </>
    );
};
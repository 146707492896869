import React,{useState, useEffect} from 'react';
import { findEmpresaByID, findEmpresaByRUC } from '../../../services/apis.js';
import ConfiguracionEmail from './ConfiguracionEmail';
import ConfiguracionPassword from './ConfiguracionPassword';
import ConfiguracionPrivacidad from './ConfiguracionPrivacidad';


export default function Section1() {

    const company = JSON.parse(localStorage.getItem('company'));
    const GetUserID = company.codeId;

    const [search, setSearch] = useState(null);
    const [paises, setPaises] = useState(null);
    const [departamentos, setDepartamentos] = useState(null);

    useEffect( () => {

        async function fetchData() {

            // RUC
            try {
                const res = await findEmpresaByID(GetUserID);
                //console.log(res);
                setSearch(res);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);

    return (
        <div className="pxp-dashboard-content-details">
            <h1>Configuración</h1>

                <div className="mt-4 mt-lg-5">
                    <div className="table-responsive">
                        <table className="table align-middle">
                            <thead></thead>
                            <tbody>
                                <ConfiguracionEmail search={search} />
                                <ConfiguracionPassword search={search} />
                                <ConfiguracionPrivacidad />
                            </tbody>
                            <tfoot></tfoot>
                        </table>
                    </div>
                </div>

        </div>
    )
}
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Section1 from './sections/Section1';

export default function GestionCandidato() {

    const { id } = useParams();
    //console.log("TERMINAL"+id);
    //console.log(this.props);

    return (
        <>
        <div className="pxp-dashboard-content-details">
            <Section1 candidatoID = {id} />
        </div>
        
        </>
    );
}
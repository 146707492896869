import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css'
import phSmall from '../../assets/images/ph-small.jpg';
import logo from '../../assets/images/Tconvoco-logo.png';
import { useCookies } from 'react-cookie';

let urlDomain = process.env.REACT_APP_URL_DOMAIN;
let urlPublico = process.env.REACT_APP_URL_PUBLIC;
let urlFile = process.env.REACT_APP_URL_FILE;

export default function Header() {

    const user = JSON.parse(localStorage.getItem('company'));
    const [cookies, setCookie, removeCookie] = useCookies(['accessTokenCompany', 'company']);

    const handleLogout = () => {

        localStorage.removeItem("accessTokenCompany");
        localStorage.removeItem("company");

        localStorage.clear();
        sessionStorage.clear();

        removeCookie('accessTokenCompany',{ path:'/', domain: urlDomain } );
        removeCookie('company',{ path:'/', domain: urlDomain });

        //window.location.href = "/login";
        window.location.href = `${urlPublico}`;
    };

    return (
        <div className="pxp-dashboard-content-header">
        <div className="pxp-nav-trigger navbar pxp-is-dashboard d-lg-none">
            <a role="button" data-bs-toggle="offcanvas" data-bs-target="#pxpMobileNav" aria-controls="pxpMobileNav">
                <div className="pxp-line-1"></div>
                <div className="pxp-line-2"></div>
                <div className="pxp-line-3"></div>
            </a>
            <div className="offcanvas offcanvas-start pxp-nav-mobile-container pxp-is-dashboard" tabIndex="-1" id="pxpMobileNav">
                <div className="offcanvas-header">
                    <div className="pxp-logo " style={{ maxWidth: "25%" }}>
                        <a href={urlPublico} className="pxp-animate"><img src={logo} alt="-" style={{ maxWidth: "50%" }} /></a>
                    </div>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body" >
                    <nav className="pxp-nav-mobile">
                        <ul className="navbar-nav justify-content-end flex-grow-1">
                            <li className="nav-item"><a href="company-dashboard.html"><span className="fa fa-home"></span>Dashboard</a></li>
                            <li className="nav-item"><a href="company-dashboard-profile.html"><span className="fa fa-pencil"></span>Edit Profile</a></li>
                            <li className="nav-item"><a href="company-dashboard-new-job.html"><span className="fa fa-file-text-o"></span>New Job Offer</a></li>
                            <li className="nav-item"><a href="company-dashboard-jobs.html"><span className="fa fa-briefcase"></span>Manage Jobs</a></li>
                            <li className="nav-item"><a href="company-dashboard-candidates.html"><span className="fa fa-user-circle-o"></span>Candidates</a></li>
                            <li className="nav-item"><a href="company-dashboard-subscriptions.html"><span className="fa fa-credit-card"></span>Subscriptions</a></li>
                            <li className="nav-item"><a href="company-dashboard-password.html"><span className="fa fa-lock"></span>Change Password</a></li>
                            <li className="pxp-dropdown-header mt-4">Insights</li>
                            <li className="nav-item">
                                <a href="company-dashboard-inbox.html" className="d-flex justify-content-between align-items-center">
                                    <div><span className="fa fa-envelope-o"></span>Inbox</div>
                                    <span className="badge rounded-pill">14</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a href="company-dashboard-notifications.html" className="d-flex justify-content-between align-items-center">
                                    <div><span className="fa fa-bell-o"></span>Notifications</div>
                                    <span className="badge rounded-pill">5</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <nav className="pxp-user-nav pxp-on-light">
            <a href="/publicar-aviso" className="btn rounded-pill pxp-nav-btn" >Publicar Trabajo</a>
            <div className="dropdown pxp-user-nav-dropdown pxp-user-notifications" style={{"display":"none"}}>
                <a role="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                    <span className="fa fa-bell-o"></span>
                    <div className="pxp-user-notifications-counter">5</div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                    <li><a className="dropdown-item" href="company-dashboard-notifications.html"><strong>Scott Goodwin</strong> applied for <strong>Software Engineer</strong>. <span className="pxp-is-time">20m</span></a></li>
                    <li><a className="dropdown-item" href="company-dashboard-notifications.html"><strong>Alayna Becker</strong> sent you a message. <span className="pxp-is-time">1h</span></a></li>
                    <li><a className="dropdown-item" href="company-dashboard-notifications.html"><strong>Erika Tillman</strong> applied for <strong>Team Leader</strong>. <span className="pxp-is-time">2h</span></a></li>
                    <li><a className="dropdown-item" href="company-dashboard-notifications.html"><strong>Scott Goodwin</strong> applied for <strong>Software Engineer</strong>. <span className="pxp-is-time">5h</span></a></li>
                    <li><a className="dropdown-item" href="company-dashboard-notifications.html"><strong>Alayna Becker</strong> sent you a message. <span className="pxp-is-time">1d</span></a></li>
                    <li><a className="dropdown-item" href="company-dashboard-notifications.html"><strong>Erika Tillman</strong> applied for <strong>Software Engineer</strong>. <span className="pxp-is-time">3d</span></a></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a className="dropdown-item pxp-link" href="company-dashboard-notifications.html">Read All</a></li>
                </ul>
            </div>
            <div className="dropdown pxp-user-nav-dropdown">
                <a role="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                    <div className="pxp-user-nav-avatar pxp-cover" style={{backgroundImage: `url(${phSmall})`}}></div>
                    <div className="pxp-user-nav-name d-none d-md-block">Empresa</div>
                </a>
                <ul className="dropdown-menu dropdown-menu-end">
                    <li><a className="dropdown-item" href="/mis-avisos">Mis Avisos</a></li>
                    <li><a className="dropdown-item" href="/empresa">Empresa</a></li>
                    <li><a className="dropdown-item" onClick={handleLogout} >Salir</a></li>
                </ul>
            </div>
        </nav>
    </div>
    );
}
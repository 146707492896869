import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Section1 from './sections/Section1';

export default function AvisoEditar() {
    const { id } = useParams();
    return (
        <>
        <Section1 AvisoID={id} />
        </>
    );
}
import React,{useState, useEffect} from 'react';
import { findCandidato, findCandidatoExperienciaLaboral, findConocimientoByIDCandidato, findFormacionAcademicaByIDCandidato } from '../../../services/apis';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import CvVerPostulanteIzquierda from './CvVerPostulanteIzquierda';
import CvVerPostulanteDerecho from './CvVerPostulanteDerecho';

export default function Section1({candidatoID}) {

    const [search, setSearch] = useState(null);
    const [experiencia, setExperiencia] = useState(null);

    const [forAcademicaList, setforAcademicaList] = useState(null);
    const [conocimientoList, setConocimientoList] = useState(null);
    
    useEffect( () => {

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                const res = await findCandidato(candidatoID);
                console.log(res);
                setSearch(res);

            } catch (e) {
                console.error(e);
            }

            // LISTA EXPERIENCIA LABORAL
            try {
                const res = await findCandidatoExperienciaLaboral(candidatoID);
                console.log(res);
                setExperiencia(res);

            } catch (e) {
                console.error(e);
            }

            // FORMACION
            try {

                const formList = await findFormacionAcademicaByIDCandidato(candidatoID);
                
                console.log(formList);
                setforAcademicaList(formList);

            } catch (e) {
                console.error(e);
            }

            // CONOCIMIENTOS Y HABILIDADES
            try {

                const ConoList = await findConocimientoByIDCandidato(candidatoID);
                
                console.log(ConoList);
                setConocimientoList(ConoList);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);


    return (
        <section >
            <div className="pxp-container">
                <div className="row">
                    <div className="col-lg-5 col-xl-4 col-xxl-4">
                        <CvVerPostulanteIzquierda search ={search} />
                    </div>
                    <div className="col-lg-7 col-xl-8 col-xxl-8">
                        <CvVerPostulanteDerecho experiencia={experiencia} foracademica={forAcademicaList} conocimientos={conocimientoList} />
                    </div>
                </div>
            </div>
        </section>
    )
}
//import React from 'react';
import React, { useState, useEffect } from "react";
import { useFormA } from '../../../hooks/useFormA';
import { findDistrito, findPronvicia } from "../../../services/apis";
//import Select from "react-select";

let newDate = new Date();
let date = newDate.getDate();
let month = newDate.getMonth();
let year = newDate.getFullYear();

let fechaActual = newDate.toLocaleString('en-US', { hour12: false });


const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.cargo.trim()){
        errors.cargo = "El campo Nombre es requerido";
    } else if( !regexNombres.test(form.cargo.trim()) ) {
        errors.cargo = "El campo Nombre solo acepta letras y espacios";
    }

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

const FrmEditarAviso = ({search, departamentos}) => {

    
    const [provincias, setProvincias] = useState(null);
    const [distritos, setDistritos] = useState(null);
    const [country, setCountry] = useState(null);

    //console.log("LISTAs - PREV");
    //if(!search) return null;
    //console.log("LISTAs - COMP");

    const initialForm = {
        idempleo : search.id,
        idCompania: search.compania.id,
        idTipoEmpleo: search.tipoEmpleoEnum.id,
        idExperiencia: "1",
        idModalidadEmpleo: "1",
        idTipoSalario: "1",
        idTipoContrato: search.tipoContratoEnum.id,
        destacada: search.destacada,
        urgente: search.urgente,
        ocultarNombreEmpresa: search.ocultarNombreEmpresa,
        mostrarEmail: search.mostrarEmail,
        mostrarTelefono: search.mostrarTelefono,
        mostrarDireccion: search.mostrarDireccion,
        cargo: search.cargo,
        categoria: search.categoria,
        descripcion: search.descripcion,
        responsabilidades: search.responsabilidades,
        requisitos: search.requisitos,
        habilidades: search.habilidades,
        distrito: search.distrito.id,
        habilitaSalario: search.habilitaSalario,
        nombre: search.nombre,
        salario: search.salario,
        publicacion: search.publicacion,
        inicio: search.inicio,
        fin: search.fin,
        vigente: search.vigente
    };

    const {
        form, 
        errors, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormA(initialForm, validationsForms);



    const handleDepartamentoChange = e => {
        //handleChange = e =>
        console.log(e.target.value)
        const valor = e.target.value;
        /*setCountry(obj);
        setLangList(obj.languages);
        setLang(null);*/

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                const res = await findPronvicia(valor);
                console.log(res);
                setProvincias(res);

                //console.log(res[0].id)

                try {
                    const redi = await findDistrito(res[0].id);
                    console.log(redi);
                    setDistritos(redi);
    
                } catch (e) {
                    console.error(e);
                }

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();
    };

    const handleProvinciaChange = e => {
        //handleChange = e =>
        console.log(e.target.value)
        const valor = e.target.value;
        /*setCountry(obj);
        setLangList(obj.languages);
        setLang(null);*/

        async function fetchData() {

            try {
                const res = await findDistrito(valor);
                console.log(res);
                setDistritos(res);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();
    };

    useEffect( () => {

        

    },[]);
    

    
    
    return (
        <div className="pxp-dashboard-content-details">
            <h1>Editar anuncio</h1>

            <form onSubmit={handleSubmit}>
                <div className="mt-4 mt-lg-5">
                    <h2>Visibilidad del anuncio</h2>
                    
                    <div className="table-responsive">
                        <table className="table align-middle">
                            <tr style={{ "borderStyle": "hidden" }}>
                                <td style={{width: "30%", "textAlign": "right" }}><div className="pxp-candidate-dashboard-experience-title">Oferta destacada</div></td>
                                <td style={{width: "15%"}}>
                                    <div className="pxp-candidate-dashboard-experience-school">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="destacada" id="destacada" value="1" onChange={handleChange} checked={form.destacada == "1"}/>
                                            <label className="form-check-label" htmlFor="searchOfertaDestacada">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="destacada" id="destacada" value="0" onChange={handleChange} checked={form.destacada == "0"}/>
                                            <label className="form-check-label" htmlFor="searchOfertaDestacada">No</label>
                                        </div>
                                    
                                    </div>
                                </td>
                                <td style={{width: "25%", "display":"none"}}>
                                    2 unidades disponibles
                                </td>
                                <td style={{"display":"none"}} >
                                    <div className="input-group"><span className="btn bg-danger text-white"> 18/20 </span></div>
                                </td>
                            </tr>

                            <tr style={{ "borderStyle": "hidden" }}>
                                <td style={{width: "30%", "textAlign": "right" }}><div className="pxp-candidate-dashboard-experience-title">Aviso Urgente</div></td>
                                <td style={{width: "15%"}}>
                                    <div className="pxp-candidate-dashboard-experience-school">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="urgente" id="urgente" value="1" onChange={handleChange} checked={form.urgente == "1"} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="urgente" id="urgente" value="0" onChange={handleChange} checked={form.urgente == "0"} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    
                                    </div>
                                </td>
                                <td style={{width: "25%", "display":"none"}}>
                                    6 unidades disponibles
                                </td>
                                <td style={{"display":"none"}}>
                                    <div className="input-group"><span className="btn bg-danger text-white"> 14/20 </span></div>
                                </td>
                            </tr>

                            <tr style={{ "borderStyle": "hidden" }}>
                                <td style={{width: "30%", "textAlign": "right" }}><div className="pxp-candidate-dashboard-experience-title">Ocultar nombre de la empresa</div></td>
                                <td style={{width: "15%"}}>
                                    <div className="pxp-candidate-dashboard-experience-school">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="ocultarNombreEmpresa" id="ocultarNombreEmpresa" value="1" onChange={handleChange} checked={form.ocultarNombreEmpresa == "1"} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="ocultarNombreEmpresa" id="ocultarNombreEmpresa" value="0" onChange={handleChange} checked={form.ocultarNombreEmpresa == "0"} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    
                                    </div>
                                </td>
                                <td style={{width: "25%", "display":"none"}}>
                                    5 unidades disponibles
                                </td>
                                <td style={{"display":"none"}}>
                                    <div className="input-group"><span className="btn bg-danger text-white"> 11/20 </span></div>
                                </td>
                            </tr>

                            <tr style={{ "borderStyle": "hidden" }}>
                                <td style={{width: "30%", "textAlign": "right" }}><div className="pxp-candidate-dashboard-experience-title">Mostrar e-mail de contacto</div></td>
                                <td style={{width: "15%"}}>
                                    <div className="pxp-candidate-dashboard-experience-school">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="mostrarEmail" id="mostrarEmail" value="1" onChange={handleChange} checked={form.mostrarEmail == "1"} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="mostrarEmail" id="mostrarEmail" value="0" onChange={handleChange} checked={form.mostrarEmail == "0"} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    
                                    </div>
                                </td>
                                <td style={{width: "25%"}}>
                                    
                                </td>
                                <td>
                                
                                </td>
                            </tr>

                            <tr style={{ "borderStyle": "hidden" }}>
                                <td style={{width: "30%", "textAlign": "right" }}><div className="pxp-candidate-dashboard-experience-title">Mostrar teléfono de contacto</div></td>
                                <td style={{width: "15%"}}>
                                    <div className="pxp-candidate-dashboard-experience-school">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="mostrarTelefono" id="mostrarTelefono" value="1" onChange={handleChange} checked={form.mostrarTelefono == "1"} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="mostrarTelefono" id="mostrarTelefono" value="0" onChange={handleChange} checked={form.mostrarTelefono == "0"} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    
                                    </div>
                                </td>
                                <td style={{width: "25%"}}>
                                    
                                </td>
                                <td>
                                
                                </td>
                            </tr>

                            <tr style={{ "borderStyle": "hidden" }}>
                                <td style={{width: "30%", "textAlign": "right" }}><div className="pxp-candidate-dashboard-experience-title">Mostrar dirección de contacto</div></td>
                                <td style={{width: "15%"}}>
                                    <div className="pxp-candidate-dashboard-experience-school">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="mostrarDireccion" id="mostrarDireccion" value="1" onChange={handleChange} checked={form.mostrarDireccion == "1"} />
                                            <label className="form-check-label" htmlFor="inlineRadio1">Si</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="radio" name="mostrarDireccion" id="mostrarDireccion" value="0" onChange={handleChange} checked={form.mostrarDireccion == "0"} />
                                            <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                                        </div>
                                    
                                    </div>
                                </td>
                                <td style={{width: "25%"}}>
                                    
                                </td>
                                <td>
                                
                                </td>
                            </tr>
                            
                        </table>
                    </div>
                </div>


                <div className="mt-4 mt-lg-5">
                    <h2>Datos del aviso</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">Nombre del puesto requerido</label>
                                <input type="text" name="nombre" className="form-control" placeholder="Nombre del puesto requerido" onChange={handleChange} value={form.nombre} autoComplete="off" maxLength="200" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">Cargo requerido</label>
                                <input type="text" name="cargo" className="form-control" placeholder="Administrativo, Técnico, Ingeniería, Otros" onChange={handleChange} value={form.cargo} autoComplete="off" maxLength="200" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">Categoría</label>
                                <input type="text" name="categoria" className="form-control" placeholder="Categoría" onChange={handleChange} value={form.categoria} autoComplete="off" maxLength="200" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb-3">
                            <label className="form-label">Descripción del anuncio</label>
                            <textarea className="form-control" name="descripcion" placeholder="Descripción del anuncio" onChange={handleChange} value={form.descripcion}></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb-3">
                            <label className="form-label">Requisitos</label>
                            <textarea className="form-control" name="requisitos" placeholder="Requisitos del anuncio" onChange={handleChange} value={form.requisitos}></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb-3">
                            <label className="form-label">Responsabilidades</label>
                            <textarea className="form-control" name="responsabilidades" placeholder="Responsabilidades del anuncio" onChange={handleChange} value={form.responsabilidades}></textarea>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mb-3">
                            <label className="form-label">Habilidades</label>
                            <textarea className="form-control" name="habilidades" placeholder="Habilidades del anuncio" onChange={handleChange} value={form.habilidades}></textarea>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">País</label>
                                <select className="form-select" name="pais" onChange={handleChange} defaultValue={1}>
                                    <option value="1">Perú</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">Departamento</label>
                                <select className="form-select" name="departamento" onChange={handleDepartamentoChange} required >
                                    <option value="" >Seleccionar</option>
                                    {
                                        departamentos
                                        ? 
                                        departamentos.map(({id, nombre},index)=>{
                                            return <option value={id} key={index}>{nombre}</option>
                                            ;
                                        })
                                        :
                                        <option value="" >Seleccionar</option> //null
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">Provincia</label>
                                <select className="form-select" name="provincia" onChange={handleProvinciaChange} required >
                                    {
                                        provincias
                                        ? 
                                        provincias.map(({id, nombre},index)=>{
                                            return <option value={id} key={index}>{nombre}</option>
                                            ;
                                        })
                                        :
                                        <option value="" >Seleccionar</option> //null
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">Ciudad o Distrito</label>
                                <select className="form-select" name="distrito" onChange={handleChange}  defaultValue={1} required >
                                    {
                                        distritos
                                        ? 
                                        distritos.map(({id, nombre},index)=>{
                                            return <option value={id} key={index} >{nombre}</option>
                                            ;
                                        })
                                        :
                                        <option value="" >Seleccionar</option> //null
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">Tipo de Empleo</label>
                                <select className="form-select" name="idTipoEmpleo" onChange={handleChange} defaultValue={form.idTipoEmpleo}>
                                    <option value="1">Junior</option>
                                    <option value="2">Asistente</option>
                                    <option value="3">Senior</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">Tipo de contrato</label>
                                <select className="form-select" name="idTipoContrato" onChange={handleChange} defaultValue={form.idTipoContrato}>
                                    <option value="1">Jornada completa</option>
                                    <option value="2">Media jornada</option>
                                    <option value="3">Contrato por obra</option>
                                    <option value="4">Temporal</option>
                                    <option value="5">Voluntario</option>
                                    <option value="6">Prácticas</option>
                                    <option value="7">Otros</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="mb-3">
                                <label htmlFor="pxp-company-country" className="form-label">Sueldo</label>
                                <input type="text" name="salario" id="salario" className="form-control" placeholder="Sueldo" value={form.salario} onChange={handleChange} autoComplete="off" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="mt-4 mt-lg-5 d-lg-none">
                    <h2>Preguntas con Filtro</h2>
                    <p>Agrega preguntas que te permitan filtrar de una manera más especifica a los talentos que postulan a tu anuncio. Solo puedes realizar máximo 5 preguntas filtro.</p>
                    
                    <button className="btn rounded-pill pxp-section-cta bg-success">Agregar preguntar</button>

                </div>



                <div className="mt-4 mt-lg-5 text-center">
                    <button type="submit" className="btn rounded-pill pxp-section-cta">Actualizar Publicación</button>
                </div>
            </form>
        </div>
    );

}

export default FrmEditarAviso;
import React from 'react';


export default function Section1() {

    return (
        <div className="pxp-dashboard-content-details">
            <h1>Privacidad</h1>

                <section class="mt-4 mt-lg-5">
                    <div class="pxp-container">
                        <div class="row justify-content-center">
                            <div class="col-xl-10 col-xxl-10">
                                <h2 class="pxp-section-h2 text-center">POLÍTICA DE PRIVACIDAD</h2>
                                <h5 class="">1. NUESTRO COMPROMISO CON LA PRIVACIDAD</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>La empresa OPSO, sus empresas afiliadas, subsidiarias o controladoras (en adelante “Tconvoco”), respetan la privacidad de toda persona que visite el sitio web www.tconvoco.com (en adelante, el "Sitio Web").</p>
                                    <p>Esta Política de Privacidad indica la información que Tconvoco puede recopilar y el uso que puede dar a esa información. También explica las medidas de seguridad tomadas para proteger su información, su posibilidad de acceder a su información, y a quién podrá contactar en Tconvoco para que sus preguntas en relación con esta Política de Privacidad sean contestadas.</p>
                                </div>
                                <h5 class="mt-lg-5">2. RECOPILACIÓN Y UTILIZACIÓN DE SU INFORMACIÓN</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>2.1. Esta Política de Privacidad contempla la recopilación y uso de información personal en el Sitio Web.</p>
                                    <p>2.2. Tconvoco no recopilará a través del Sitio Web ninguna información sobre usted que pueda identificarlo personalmente, como, por ejemplo, su nombre, dirección, número de teléfono o dirección de correo electrónico (en adelante, la " Información Personal "), a menos que usted nos la brinde en forma voluntaria. Si usted no desea que recopilemos su Información Personal, por favor absténgase de proporcionarla. Sin embargo, deberá tener en cuenta que, en caso de no proporcionarnos su Información Personal, no podrá acceder a los servicios e información disponibles a través del Sitio Web.</p>
                                    <p>2.3. En caso de que usted nos brinde Información Personal, le comunicamos que esa Información Personal será objeto de tratamiento automatizado e incorporada a la base de datos de Tconvoco.</p>
                                    <p>2.4. Tconvoco en ningún momento recopilará información sensible sobre usted.</p>
                                </div>
                                <h5 class="mt-lg-5">3. INFORMACIÓN PERSONAL</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>3.1. Tconvoco recopila Información Personal en línea cuando, entre otros casos:</p>
                                    <p>3.2. Su Información Personal podrá ser tratada por Tconvoco, sus empresas relacionadas y aliados comerciales directamente o a través de sus proveedores, para:</p>
                                    <p>3.3. El tipo de información recopilada puede incluir nombre, tipo y número de documento, teléfono, dirección de correo electrónico y/o cualquier otra información que permita individualizarlo. En todos los casos que usted brinde Información Personal, y de acuerdo a la legislación vigente, usted declara que la Información Personal brindada es correcta, cierta y actual.</p>
                                    <p>3.4. En los casos que nos brinde su Información Personal, usted acepta y presta su consentimiento libre, previo, expreso e informado para que dicha Información Personal sea utilizada con las finalidades arriba mencionadas y autoriza a que la misma sea tratada, almacenada, recopilada en la base de datos USUARIOS DE PLATAFORMAS WEB Y MÓVIL propiedad de Tconvoco.</p>
                                    <p>En tal sentido, usted autoriza a que su Información Personal sea compartida con los clientes de Tconvoco, los cuales se encuentran a la vista en nuestro Sitio Web. Asimismo, usted acepta y presta su consentimiento libre, previo, expreso e informado con los términos de la presente Política de Privacidad.</p>
                                    <p>3.5. Tconvoco almacenará los datos personales de sus usuarios hasta por un máximo de 25 años, o hasta que el usuario dé de baja su cuenta en nuestro Portal; o si el usuario hace uso de su derecho de cancelación o supresión, lo que ocurra primero. Luego de ello, sus datos serán eliminados.</p>

                                </div>
                                <h5 class="mt-lg-5">4. CORREO ELECTRÓNICO</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>4.1. Tconvoco podrá enviarle correos electrónicos en relación con el contenido del Sitio Web, los servicios prestados por Tconvoco o sobre su cuenta y en respuesta a sus preguntas, pedidos, consultas o comentarios. Tconvoco también le podrá enviar correos electrónicos con información sobre productos y servicios ofrecidos por Tconvoco y/o terceros asociados comercialmente que le puedan resultar de interés, a menos que usted indique expresamente que no desea recibir dichos correos electrónicos a través de los procesos implementados por Tconvoco a tal efecto.</p>
                                    <p>4.2. Todo correo electrónico que reciba de Tconvoco le informará cómo rechazar el envío de futuros correos electrónicos promocionales. Asimismo, usted podrá cambiar sus preferencias de recepción de correos electrónicos publicitarios a través de la configuración de su cuenta en el propio Sitio Web en cualquier momento.</p>
                                </div>
                                <h5 class="mt-lg-5">5. OTRA INFORMACIÓN - COOKIES</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>Cuando usted ingresa en el Sitio Web podemos almacenar alguna información en su computadora bajo la forma de “Cookie” o archivo similar que puede sernos útil de varias formas. Con la mayoría de los exploradores para Internet, usted puede borrar las Cookies del disco rígido de su computadora, bloquear todas las Cookies o recibir un mensaje de alerta antes de que se almacene una Cookie. Por favor, consulta las instrucciones de su explorador para conocer más sobre estas funciones.</p>
                                </div>
                                <h5 class="mt-lg-5">POLÍTICA DE COOKIES</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>¿Qué son las cookies?</p>
                                    <p>Una cookie es un pequeño archivo colocado en la computadora del usuario, smartphone u otro dispositivo electrónico que habilita las funcionalidades de nuestro portal web. Por ejemplo, las cookies nos permiten identificar el dispositivo del usuario, ofrecerle acceso seguro al portal web, e incluso nos ayudan a saber si alguien intenta acceder a la cuenta del mismo desde otro dispositivo. Las cookies también hacen seguimiento de la sesión del usuario, mejoran el tiempo de carga de una web, evitan mostrar información reiterativa y nos ayudan a mostrarte anuncios relevantes para el usuario.</p>
                                    <p>¿Por qué utilizamos cookies?</p>
                                    <p>Para mejorar la experiencia de navegación como usuario, siendo la mejor manera de ofrecer contenido personalizado y de interés a través del portal web y aplicación móvil. Asimismo, nos permiten ofrecer publicidad relevante y basada en los intereses de los usuarios que generan ingresos que nos permiten mantener la gratuidad de otros servicios.</p>
                                    <p>¿Qué cookies utilizamos?</p>
                                    <p>Las cookies que utilizamos en esta página web/aplicación móvil son las siguientes:<br/>
                                    Autenticación: permiten mostrar la información adecuada y a personalizar la experiencia del usuario, ayudándonos a determinar si ha accedido o no a la cuenta del sitio web.<br/>
                                    Funcionalidades y servicios: esenciales para el correcto funcionamiento de los servicios. Proporcionan funcionalidades y contenidos personalizados.<br/>
                                    Analíticas: permiten monitorizar el rendimiento de nuestros sitios webs y herramientas online. Por ejemplo: analizar patrones de comportamiento, número de usuarios que acceden, secciones visitadas, duración de la navegación, crear informes estadísticos sobre el tráfico web, audiencia global.
                                    </p>
                                    <p>¿Qué cookies de terceros utilizamos?</p>
                                    <p>Las cookies de terceros que alojamos en nuestro portal web/aplicación móvil son las siguientes:<br/>
                                    Analíticas: permiten monitorizar el rendimiento de nuestros sitios webs y/o herramienta, tal y como se ha indicado anteriormente.
                                    </p>
                                    <p>Los terceros que podrían tener acceso a esta información son:</p>
                                    <p>Google Analytics: <br/>
                                    http://www.google.com/intl/en/policies/technologies/cookies<br/>
                                    Para consultar la política de privacidad acerca de las cookies de los referidos terceros, recomendamos acceder a sus condiciones legales a través de los anteriores enlaces.<br/>
                                    Publicitarias: permiten gestionar y adaptar el contenido al servicio solicitado, y los espacios publicitarios ofertados en su caso, en nuestros sitios web. Así podemos analizar comportamientos de navegación en internet y mostrar al usuario los anuncios que mejor se adapten a sus intereses. Además, las cookies son necesarias para gestionar campañas publicitarias, mediante el seguimiento de diversas pautas como número de veces que se ha visto un anuncio, o para mejorar y gestionar la exposición de anuncios, evitando al usuario la publicidad que ya se le ha mostrado.<br/>
                                    También las cookies publicitarias permiten informar, optimizar y reportar nuestras impresiones de anuncios, otros usos de los servicios de publicidad, las interacciones con estas impresiones y servicios de publicidad repercuten en las visitas al sitio, y ofrecer publicidad basada en anteriores visitas que el usuario ha realizado a nuestra web.<br/>
                                    Los terceros con los que trabajamos o podemos llegar a trabajar, que utilizan este tipo de cookies son:<br/>
                                    DoubleClick: (http://www.google.com/doubleclick)<br/>
                                    Appnexus: (https://www.appnexus.com/en/company/cookie-policy)<br/>
                                    OpenX: (https://www.openx.com/legal/privacy-policy/)<br/>
                                    Rhythmone: (https://www.rhythmone.com/privacy-policy)<br/>
                                    E-planning: (https://www.e-planning.net/es/privacy.html)<br/>
                                    Para consultar la política de privacidad acerca de las cookies de los referidos terceros, se recomienda acceder a sus condiciones legales a través de los anteriores enlaces.<br/>
                                    Control y desactivación de cookies<br/>
                                    Dejamos constancia que es posible desactivar y/o eliminar las cookies, siguiendo las indicaciones del navegador de internet. Sin embargo, debe tenerse en cuenta que en caso los usuarios desactiven y/o eliminen las cookies en posible que se reduzca considerablemente su capacidad para utilizar nuestros servicios.<br/>
                                    Alternativas externas:<br/>
                                    'Opt-out' (desactivación) para cada tipo de cookies: el uso de este sistema puede instalar en tu computadora una cookie "de rechazo" para hacer efectiva la petición de desactivación de cookies solicitada.<br/>
                                    Otras herramientas de terceros disponibles online: te permiten detectar todas las cookies de cada web visitada y gestionar su desactivación.<br/>
                                    Sistemas "opt-out" disponibles para Google Analytics: https://tools.google.com/dlpage/gaoptout/<br/>
                                    Computrabajo no se hace responsable del contenido y veracidad de las políticas de privacidad de los terceros referenciadas en esta política de cookies. Para resolver cualquier duda relacionada, deberás contactar con nosotros a través del formulario de contacto.
                                    </p>
                                    <p>ACTUALIZACIONES</p>
                                    <p>Nos reservamos el derecho de actualizar la presente Política de Privacidad cuando así se considere oportuno mediante la publicación de una versión actualizada en el portal web de Computrabajo. Si realizamos alguna modificación que pudiera afectar los derechos del Usuario se le notificará por email o mediante un aviso en el portal web de Computrabajo.<br/>
                                    Recomendamos al Usuario que revise de forma periódica la Política de Privacidad y Cookies para estar actualizado de todas las novedades.<br/>
                                    En caso de estar de acuerdo con los términos señalados en esta Política de Privacidad y Cookies el Usuario deberá hacer “click” en el recuadro de aceptación correspondiente. Asimismo, mediante dicha aceptación, el Usuario declara que el uso continuado de nuestros servicios tras publicar o, en su caso, enviar un aviso acerca de nuestros cambios en esta Política de Privacidad y Cookies implica que la recopilación, la utilización y tratamiento de sus Datos Personales están sujetos a la Política de Privacidad y Cookies actualizada. Asimismo, al dar “click” en el recuadro de aceptación el Usuario autoriza expresamente a  Tconvoco recabar y procesar sus Datos Personales bajo los términos descritos en el presente documento, datos cuyo tratamiento es necesario para que Tconvoco pueda proporcionar sus servicios de la mejor manera posible.<br/>
                                    Si el Usuario no está de acuerdo con cualquiera de los cambios, goza del pleno derecho de cerrar su cuenta de usuario del portal web Tconvoco.<br/>
                                    </p>
                                </div>
                                <h5 class="mt-lg-5">6. COMPARTIENDO SU INFORMACIÓN</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>La Información Personal que usted suministre será reputada y tratada como confidencial. Sin perjuicio de lo expuesto, Tconvoco podrá compartir total o parcialmente la Información Personal suministrada por usted, incluyendo registros de comportamiento y actividad en el Sitio, contactos a terceros que anuncien u ofrezcan productos o servicios en el Sitio Web, parámetros de interés de búsquedas así como su historial de búsqueda y preferencias a empresas del grupo Tconvoco (para conocer las empresas del grupo Tconvoco, ver: www.tconvoco.com  ), así como entidades gubernamentales cuando nos requieran dicha información o se trate de una obligación legal. Asimismo, se hace transferencia internacional de datos a Google LLC, ubicada en Estados Unidos de América, para fines del servicio de hosting de nuestro Portal.</p>
                                    <p>Asimismo, en el evento de vender todo o parte del negocio de Tconvoco, usted autoriza a Tconvoco a transferir al comprador las bases de datos que contiene su Información Personal. De ser el caso, se le comunicará de este hecho oportunamente.</p>
                                    <p>Al margen de lo establecido en la presente Política de Privacidad, Tconvoco no podrá vender o divulgar su Información Personal a terceros salvo que haya obtenido su consentimiento previamente o esté obligado a hacerlo por ley.</p>
                                    <p>Tconvoco será responsable del cumplimiento efectivo de las obligaciones referentes al tratamiento de Información Personal por sus empresas relacionadas, sin perjuicio de la responsabilidad que les quepa a éstas por cualquier incumplimiento de tales obligaciones. Del mismo modo, en caso que el tratamiento de Información Personal se haya de efectuar por empresas proveedoras de servicios para Tconvoco, aliados comerciales o sus empresas relacionadas, dichas empresas deberán asumir compromisos de confidencialidad y adoptar medidas que aseguren el debido cumplimiento de las obligaciones establecidas en la Ley de Protección de Datos Personales- Ley No 29733 y su Reglamento Decreto Supremo N° 003-2013-JUS.</p>
                                </div>
                                <h5 class="mt-lg-5">7. PROTEGIENDO SU INFORMACIÓN PERSONAL</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>7.1. Para prevenir acceso no autorizado, mantener la precisión de los datos y asegurar el uso correcto de su Información Personal, Tconvoco ha puesto en uso ciertos medios físicos, electrónicos, administrativos y procedimientos de seguridad para resguardar y asegurar la Información Personal que recopilamos en línea. Nosotros resguardamos la Información Personal de acuerdo a estándares y procedimientos de seguridad establecidos y continuamente evaluamos nueva tecnología para proteger esa información. Tconvoco garantiza que los procesos internos propios de las bases de datos cumplen con las obligaciones legales de seguridad y confidencialidad impuestas por las leyes de cada país en materia de privacidad y protección de datos personales.</p>
                                    <p>7.2. A pesar de lo anterior, usted reconoce que los medios técnicos existentes que brindan seguridad no son inexpugnables y que aun cuando se adopten todos los recaudos razonables de seguridad es posible sufrir manipulaciones, destrucción y/o pérdida de información. De presentarse estos casos, Tconvoco procederá conforme las leyes de cada país en materia de privacidad y protección de datos lo requieran.</p>
                                    <p>7.3. Los empleados de Tconvoco son entrenados para comprender y cumplir con estos principios en materia de protección de datos personales y seguridad de la información. Asimismo, los empleados de Tconvoco asumen estrictos compromisos de confidencialidad en cuanto a la Información Personal que procesan en el ejercicio de sus funciones.</p>
                                    <p>7.4. Usted consiente, reconoce y acepta que su información personal sea almacenada en la jurisdicción de Tconvoco o que la misma pueda ser transferida, almacenada y tratada fuera de su país de residencia.</p>
                                </div>
                                <h5 class="mt-lg-5">8. MENORES DE EDAD</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>8.1. Tconvoco no tiene intenciones de recopilar Información Personal de menores de edad. Cuando corresponda, Tconvoco le indicará específicamente a los menores que no brinden esa información Personal en nuestro Sitio Web y/o tomará medidas razonables para obtener el consentimiento de los padres, tutor o representante legal para la entrega de esa Información Personal.</p>
                                    <p>8.2. Le informamos que en su condición de padre, tutor legal o representante será el responsable de que sus hijos menores o bajo su tutela accedan al Sitio Web, por lo que recomendamos enfáticamente tomar las precauciones oportunas durante la navegación en el Sitio Web. A este fin, le informamos que algunos navegadores permiten configurarse para que los niños no puedan acceder a páginas determinadas.</p>
                                </div>
                                <h5 class="mt-lg-5">9. LINKS EXTERNOS</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>El Sitio Web puede contener links hacia y provenientes de otros sitios de Internet. Tconvoco no es responsable por las prácticas de privacidad ni el tratamiento de los datos personales de esos sitios. DRICO recomienda que consulten las prácticas de privacidad de dichos sitios de Internet antes de su utilización.</p>
                                </div>
                                <h5 class="mt-lg-5">10. DERECHOS DEL USUARIO</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>10.1. Si usted ha proporcionado Información Personal a través de los servicios disponibles en el Sitio Web, usted podrá acceder a la misma, revisar, modificar, eliminar y actualizar su Información Personal en el momento que lo desee.</p>
                                    <p>10.2. Si usted desea acceder a su Información Personal o si tal Información Personales es incorrecta, desactualizada y/o suprimida, por favor tenga a bien enviar su solicitud por correo electrónico de acuerdo con las normas implementadas en su país tal como se detalla en la cláusula 12 con el asunto “Informar”, “Rectificar”, “Suprimir” y/o “Actualizar” y/o cualquier otro según corresponda, conjuntamente con el objeto de su requerimiento. Dicha solicitud deberá contener como mínimo la siguiente información:</p>
                                    <p>10.3. Asimismo, si usted se suscribe a alguno de los servicios o comunicaciones que ofrece Tconvoco podrá dar de baja su suscripción en cualquier momento siguiendo las instrucciones incluidas en cada comunicación.</p>
                                    <p>10.4. Tconvoco cooperará con las autoridades de cada país cuando éstas requieran formalmente cualquier información relacionada con nuestras bases de datos.</p>
                                </div>
                                <h5 class="mt-lg-5">11. CAMBIOS A ESTE AVISO DE PRIVACIDAD</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>Tconvoco se reserva el derecho a modificar esta Política de Privacidad periódicamente, en cuyo caso la política actualizada se publicará en este mismo sitio, siendo obligación de los usuarios revisar regularmente esta sección a fin de informarse de cualquier cambio que se pueda haber producido. De todos modos, Tconvoco cursará una comunicación a su cuenta de correo electrónico registrada a efectos de informarle sobre el cambio en la Política de Privacidad.</p>
                                </div>
                                <h5 class="mt-lg-5">12. LOCALIZACIÓN</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>12.1. PARA USUARIOS EN PERÚ</p>
                                    <p>12.1.1. Tconvoco significa OPSO S.A.C., con domicilio en Urb, Urano 215, Huancayo, PERÚ. El Sitio Web es http://www.tconvoco.pe/. La presente Política de Privacidad se rige por las leyes de Perú.</p>
                                    <p>12.1.2. Usted como titular de la Información Personal, podrá en todo momento revocar el consentimiento otorgado expresamente, tanto como limitar el uso o divulgación de su Información Personal. A usted le asiste el ejercer los derechos de acceso, rectificación, oposición y cancelación de los datos personales, para lo cual puede dirigirse al correo electrónico o su representante, podrá presentar la solicitud de ejercicio de sus derechos reconocidos en la Ley 29733 Ley de protección a los datos personales, escribiendo a soporte@opso.com.</p>
                                    <p>12.1.3. Usted tiene derecho a impedir que lo contactemos por teléfono para fines publicitarios o de prospección comercial inscribiéndose al Registro “Gracias No insista” del Instituto Nacional de Defensa de la Protección de la Propiedad Intelectual (INDECOPI). Para mayor información, consulta en https://indecopi.gob.pe/testbusqueda/-/asset_publisher/assVAmoRuJlX/content/gracias-no-insis-1?inheritRedirect=false</p>
                                    <p>12.1.4. En caso de que el usuario no reciba una respuesta satisfactoria al requerimiento de acceso, rectificación, actualización y/o supresión de su Información Personal, se le informa que la Dirección General de Protección de Datos Personales, Órgano de Control de la Ley de Protección de Datos Personales – Ley Nº 29733 y su Reglamento, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales. Puede acceder a mayor información haciendo clic en la imagen que sigue a continuación: https://www.minjus.gob.pe/proteccion-de-datos-personales/</p>
                                </div>
                                <h5 class="mt-lg-5">13. CONTACTO</h5>            
                                <div class="mt-4 mt-md-5 justify">
                                    <p>Si tiene preguntas sobre su privacidad cuando utilice el Sitio Web, por favor contáctenos a los siguientes correos electrónicos:</p>
                                    <p>Perú: soporte@opso.com </p>
                                    <p>Esta Política de Privacidad fue actualizada por última vez el 19 de diciembre de 2022.</p>
                                    <p>En caso de dudas y/o comentarios a este aviso de privacidad, el usuario podrá contactar a Bumeran, mediante comunicado electrónico al soporte@opso.com obteniendo una respuesta sobre el mismo, en un plazo de 72 horas hábiles.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

        </div>
    )
}
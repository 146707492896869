import React from 'react';
import phSmall from '../../../assets/images/ph-small.jpg';

export default function CvVerPostulanteIzquierda({search}) {

    if(!search) return null;

    return (
        <>

                    
                        <div className="pxp-jobs-list-side-filter ">

                            <div className="mt-4 mt-lg-0 d-lg-block pxp-list-side-filter-panel text-center">
                                <div className="mt-2 mt-lg-3">
                                        <img src={ phSmall } />
                                </div>

                                <h3 className="mt-3">{search.nombre} {search.apellidoPaterno}</h3>
                                <button className="btn rounded-pill pxp-nav-btn">{search.sobremiCargo}</button>
                            </div>

                            <div className="mt-4 mt-lg-0 d-lg-block pxp-list-side-filter-panel">
                                <div className="mt-4 mt-lg-5">
                                    <div className="table-responsive">
                                        <table className="table table-hover align-middle">
                                            <tbody>
                                                <tr>
                                                    <td><div className="pxp-company-dashboard-subscriptions-plan">SOBRE EL POSTULANTE</div></td>
                                                </tr>
                                                <tr>
                                                    <td><div className="pxp-company-dashboard-job-date">{search.sobremiDescripcion}</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="mt-4 mt-lg-5">
                                    <div className="table-responsive">
                                        <table className="table table-hover align-middle">
                                            <tbody>
                                                <tr>
                                                    <td><div className="pxp-company-dashboard-subscriptions-plan">INFORMACIÓN PERSONAL</div></td>
                                                </tr>
                                                <tr className="d-lg-none" >
                                                    <td><div className="pxp-company-dashboard-job-date d-lg-none">Lugar de residencia: </div></td>
                                                </tr>
                                                <tr className="d-lg-none" >
                                                    <td><div className="pxp-company-dashboard-job-date d-lg-none">Licencia de conducir: </div></td>
                                                </tr>
                                                <tr>
                                                    <td><div className="pxp-company-dashboard-job-date">Fecha Nacimiento: {search.fechaNacimiento}</div></td>
                                                </tr>
                                                <tr>
                                                    <td><div className="pxp-company-dashboard-job-date">Email: {search.mail}</div></td>
                                                </tr>
                                                <tr>
                                                    <td><div className="pxp-company-dashboard-job-date">Celular: {search.telefono}</div></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                    
        </>
    )
}
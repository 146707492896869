import React from 'react';
import { useFormE2 } from '../../../hooks/useFormE2';

const initialForm = {
    idCompania: 1,
    quienesSomos: "",
    nuestrosPilares: "",
    mision: "",
    vision: ""
};

const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.quienesSomos.trim()){
        errors.quienesSomos = "El campo Nombre es requerido";
    } else if( !regexNombres.test(form.quienesSomos.trim()) ) {
        errors.quienesSomos = "El campo Nombre solo acepta letras y espacios";
    }

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

const Section2 = ({search, paises, departamentos, provincias, distritos}) => {

    const {
        form, 
        errors, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormE2(initialForm, validationsForms);

    return (
        <form onSubmit={handleSubmit}>
            <div className="mt-4 mt-lg-5">
                <h2>Sobre la Empresa</h2>
                
                <div className="mb-3">
                    <label className="form-label">¿Quiénes Somos?</label>
                    <textarea className="form-control" name="quienesSomos" placeholder={search ? search.quienesSomos : "¿Quiénes Somos?" } value={form.quienesSomos} onChange={handleChange} ></textarea>
                </div>

                <div className="mb-3">
                    <label className="form-label">Nuestros Pilares</label>
                    <textarea className="form-control" name="nuestrosPilares" placeholder={search ? search.nuestrosPilares : "Nuestros Pilares" } value={form.nuestrosPilares} onChange={handleChange} ></textarea>
                </div>

                <div className="mb-3">
                    <label className="form-label">Misión</label>
                    <textarea className="form-control" name="mision" placeholder={search ? search.mision : "Misión" } value={form.mision} onChange={handleChange} ></textarea>
                </div>

                <div className="mb-3">
                    <label className="form-label">Visión</label>
                    <textarea className="form-control" name="vision" placeholder={search ? search.vision : "Visión" } value={form.vision} onChange={handleChange} ></textarea>
                </div>

                <div className="mt-4 mt-lg-5 text-center">
                    <button type="submit" className="btn rounded-pill pxp-section-cta">Guardar</button>
                </div>
            </div>
        </form>
    )
}

export default Section2;
import React from 'react';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import swal from 'sweetalert';

let urlEndPoint = process.env.REACT_APP_API_URL;

async function updateEvento(e) {

    const id = e.target.id.value;
    const estPostulante = e.target.estadopostulante.value;

    try {
                
        const responseFetch = await fetch(`${urlEndPoint}/postulaciones/cambioestadopostulante/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id : id,
                postulanteEstado : `${estPostulante}`,
                estado: "1",
            })
        })
        .then(data => data.json())
        .catch(error => {
            console.error('There was an error!', error);
        });

        return responseFetch;

    } catch (error) {
        console.error(error);
    }
    
}


export default function CvPostulanteDerecho({ experiencia, foracademica, conocimientos, otroConocimiento}) {

    if(!experiencia) return null;
    if(!foracademica) return null;
    if(!conocimientos) return null;
    if(!otroConocimiento) return null;

    const puestoStorage = localStorage.getItem('aviso');
    const postulacionStorage = localStorage.getItem('postula');
    const postulacionStatusStorage = localStorage.getItem('postulastatus');
    let empleo = ""; //null
    let postulacion = ""; //null
    let postulacionStatus = ""; //null

    empleo = puestoStorage ? Buffer.from(puestoStorage, 'base64').toString('ascii') : "";
    //console.log("EMPLEO ID: " + empleo)

    postulacion = postulacionStorage ? Buffer.from(postulacionStorage, 'base64').toString('ascii') : "";
    //console.log("POSTULACION ID: " + postulacion)

    postulacionStatus = postulacionStatusStorage ? Buffer.from(postulacionStatusStorage, 'base64').toString('ascii') : "";
    //console.log("POSTULACION STATUS: " + postulacionStatus)

    const updateEstadoPostulacion = async (a) => {
        //e.preventDefault();

    }

    const handleSubmit = async e => {
        e.preventDefault();
        
        e.currentTarget.disabled = true;

        const response = await updateEvento(e);
        
                if ('id' in response) {
                    swal("Actualizado", "Se actualizó la información correctamente", "success", {
                        buttons: false,
                        timer: 2000,
                    })
                    .then((value) => {
                        console.log(response)
                        var idPostulacionStatusBase64 = "";
                        idPostulacionStatusBase64 = Buffer.from(response.postulanteEstado.toString()).toString('base64');
                        localStorage.setItem('postulastatus', idPostulacionStatusBase64 );
                        //window.location.href = "/cv";
                        window.location.reload();
                    });
                } else {
                    swal("Error", "Error al actualizar", "error");
                }
    }

    return (
        <>
            <div className="row">
            <form onSubmit={handleSubmit} >
                <div className="pxp-plans-card-1">
                    <div className="row">
                        <div className="col-xxl-8">
                            <div className="input-group">
                                <span className="input-group-text"><span className="fa fa-folder-o"></span></span>
                                <input type="hidden" id="id" name='id'value={postulacion} required readOnly />
                                <select className="form-select" id="estadopostulante" name="estadopostulante" defaultValue={postulacionStatus}>
                                    <option value="1">Recibido</option>
                                    <option value="2">Seleccionado</option>
                                    <option value="3">Finalista</option>
                                    <option value="4">Descartado</option>
                                </select>
                            </div>
                        </div>

                        <div className="col-xxl-4">
                            <button type="submit" className="btn rounded-pill pxp-section-cta">Guardar</button>
                        </div>
                                
                    </div>
                </div>
</form>
                
                <div className="mt-2 ">
                    <h2>Experiencia Laboral</h2>
                    {
                        experiencia.map(({nombre, compania, descripcion, fechaIngreso, fechaTermino},index)=>{
                        return <div className="table-responsive" key={index}>
                            <table className="table table-hover align-middle">
                                <tbody>
                                    <tr>
                                        <td style={{width: "50%" }} ><div className="pxp-company-dashboard-subscriptions-plan">{nombre}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="pxp-company-dashboard-job-date">{compania}</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="pxp-company-dashboard-job-date">{fechaIngreso.substring(0,4)} - {fechaTermino.substring(0,4)}</div></td>
                                    </tr>
                                    <tr className='d-lg-none'>
                                        <td><div className="pxp-company-dashboard-job-date d-lg-none">+ 1 año y 9 meses</div></td>
                                    </tr>
                                    <tr>
                                        <td><div className="pxp-company-dashboard-job-date">{descripcion}</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        ;
                        })
                    }
                </div>      
            </div>


            <div className="row">     
                <div className="mt-4 mt-lg-5">
                    <h2>Formación Académica</h2>
                    {
                        foracademica.map(({id, areaProfesional, centroEducativo, nivelEstudios, fechaInicio, fechaTermino},index)=>{
                        return <div className="table-responsive" key={index}>
                                    <table className="table table-hover align-middle">
                                        <tbody>
                                            <tr>
                                                <td style={{width: "50%" }} ><div className="pxp-company-dashboard-subscriptions-plan">{areaProfesional}</div></td>
                                            </tr>
                                            <tr>
                                                <td><div className="pxp-company-dashboard-job-date">{centroEducativo}</div></td>
                                            </tr>
                                            <tr>
                                                <td><div className="pxp-company-dashboard-job-date">{fechaInicio.substring(0,4)} - {fechaTermino.substring(0,4)}</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                ;
                            })
                        }

                </div>       
            </div>
            <div className="row">
                <div className="mt-4 mt-lg-5">
                    <h2>Conocimiento y Habilidades</h2>
                        <div className="pxp-candidate-dashboard-skills mb-3">
                            <ul className="list-unstyled">
                                {
                                conocimientos.map(({id, nombre},index)=>{
                                return<li key={index}>{nombre}</li>
                                                ;
                                    })
                                }
                            </ul>
                        </div>
                </div>
            </div>

            <div className="row">
                <div className="mt-4 mt-lg-5">
                    <h2>Otros Conocimientos</h2>
                        <div className="pxp-candidate-dashboard-skills mb-3">
                            <ul className="list-unstyled">
                                {
                                otroConocimiento.map(({id, nombre},index)=>{
                                return<li key={index}>{nombre}</li>
                                                ;
                                    })
                                }
                            </ul>
                        </div>
                </div>
            </div>
                    
        </>
    )
}
import React,{useState, useEffect} from 'react';
import { findDepartamentos, findEmpresaByID, findEmpresaByRUC, findPaises } from '../../services/apis.js';
import Section1 from './sections/Section1.js';
import Section2 from './sections/Section2.js';
import Section3 from './sections/Section3.js';
import Section4 from './sections/Section4.js';

export default function Empresa() {

    const company = JSON.parse(localStorage.getItem('company'));
    const GetUserID = company.codeId;

    const [search, setSearch] = useState(null);
    const [paises, setPaises] = useState(null);
    const [departamentos, setDepartamentos] = useState(null);

    useEffect( () => {

        async function fetchData() {

            // RUC
            try {
                //const res = await findEmpresaByRUC(55555555);
                const res = await findEmpresaByID(GetUserID);
                console.log(res);
                setSearch(res);

            } catch (e) {
                console.error(e);
            }

            // PAISES
            try {
                const res1 = await findPaises();
                console.log(res1);
                setPaises(res1);

            } catch (e) {
                console.error(e);
            }

            // DEPA
            try {
                const res2 = await findDepartamentos(1);
                console.log(res2);
                setDepartamentos(res2);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();
        /*
            
            <Section3 />
            <Section4 />
        */
    },[]);

    return (
        <>
        <div className="pxp-dashboard-content-details">
            <Section1 search={search} paises={paises} departamentos={departamentos} />
            <Section2 search={search} />
        </div>
        </>
    );
}
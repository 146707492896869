import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/Tconvoco-logo.png';

let urlPublico = process.env.REACT_APP_URL_PUBLIC;
let urlCandidato = process.env.REACT_APP_URL_CANDIDATO;
let urlReclutador = process.env.REACT_APP_URL_RECLUTADOR;

export default function HeaderExt() {

    return (
        
        <>        
        <header className="pxp-header fixed-top">
            <div className="pxp-container">
                <div className="pxp-header-container">
                    <div className="pxp-logo " style={{ maxWidth: "25%" }}>
                        <a href={urlPublico} className="pxp-animate"><img src={logo} alt="-" style={{ maxWidth: "50%" }} /></a>
                    </div>
                    <div className="pxp-nav-trigger navbar d-xl-none flex-fill">
                        <a href="/#" role="button" data-bs-toggle="offcanvas" data-bs-target="#pxpMobileNav" aria-controls="pxpMobileNav">
                            <div className="pxp-line-1"></div>
                            <div className="pxp-line-2"></div>
                            <div className="pxp-line-3"></div>
                        </a>
                        <div className="offcanvas offcanvas-start pxp-nav-mobile-container" tabIndex={-1} id="pxpMobileNav">
                            <div className="offcanvas-header">
                                <div className="pxp-logo">
                                    <Link to="/" className="pxp-animate"><span>T</span>convoco</Link>
                                </div>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <nav className="pxp-nav-mobile">
                                    <ul className="navbar-nav justify-content-end flex-grow-1">
                                        <li className="nav-item dropdown">
                                            <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Home</a>
                                            <ul className="dropdown-menu">
                                                <li className="pxp-dropdown-header">Home Page Versions</li>
                                                <li className="nav-item"><Link to="/">Hero with Animated Cards</Link></li>
                                                <li className="nav-item"><Link to="/">Hero with Animated Cards</Link></li>
                                                <li className="nav-item"><Link to="/">Hero with Animated Cards</Link></li>
                                                <li className="nav-item"><Link to="/">Hero with Animated Cards</Link></li>

                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Find Jobs</a>
                                            <ul className="dropdown-menu">
                                                <li className="pxp-dropdown-header">Job Listings</li>
                                                <li><Link to="/">Top Search with Cards</Link></li>
                                               
                                                <li className="pxp-dropdown-header">Single Job</li>
                                                <li><Link to="/">Wide Content</Link></li>
                                               
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Companies</a>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item dropdown">
                                                    <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Companies List</a>
                                                    <ul className="dropdown-menu">
                                                        <li><Link to="/">Top Search</Link></li>
                                                       
                                                    </ul>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Single Company</a>
                                                    <ul className="dropdown-menu">
                                                        <li><Link to="/">Wide Content</Link></li>
                                                       
                                                    </ul>
                                                </li>
                                                <li className="nav-item"><a href="company-dashboard.html">Company Dashboard</a></li>
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Candidates</a>
                                            <ul className="dropdown-menu">
                                                <li className="nav-item dropdown">
                                                    <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Candidates List</a>
                                                    <ul className="dropdown-menu">
                                                        <li><Link to="/">Top Search</Link></li>
                                                       
                                                    </ul>
                                                </li>
                                                <li className="nav-item dropdown">
                                                    <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Single Candidate</a>
                                                    <ul className="dropdown-menu">
                                                        <li><Link to="/">Wide Content</Link></li>
                                                     
                                                    </ul>
                                                </li>
                                                <li><Link to="/">Candidate Dashboard</Link></li>
                                               
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Bloggg</a>
                                            <ul className="dropdown-menu">
                                                <li><Link to="/">Articles Cards</Link></li>
                                                
                                            </ul>
                                        </li>
                                        <li className="nav-item dropdown">
                                            <a href="/#" role="button" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                                            <ul className="dropdown-menu">
                                                <li><Link to="/">About Us</Link></li>
                                              
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <nav className="pxp-nav dropdown-hover-all d-none d-xl-block">
                        <ul>
                            <li className="dropdown">
                                <a href={`${urlPublico}/nosotros`}>Nosotros</a>
                            </li>
                            <li className="dropdown">
                                <a href={`${urlPublico}/registro-reclutador`}>Publicar oferta</a>
                            </li>
                            <li className="dropdown">
                                <a href={`${urlPublico}/blog`}>Blog</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header></>
    );
}
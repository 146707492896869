import React from 'react';
import { useFormUEM } from '../../hooks/useFormUEM';
import Message from '../messages/message';


const validationsForms = (form) => {
    let errors = {};

    let regexNombres = /^[.A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
    //let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
    let regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    let regexNumeros = /^\d+\.?\d*$/;


    if(!form.email_new1.trim()){
        errors.email_new1 = "El campo Email es requerido";
    } else if(!regexEmail.test(form.email_new1.trim())){
        errors.email_new1 = "El campo Email no cumple con el formato";
    }

    if(!form.email_new2.trim()){
        errors.email_new2 = "El campo Email es requerido";
    } else if(!regexEmail.test(form.email_new2.trim())){
        errors.email_new2 = "El campo Email no cumple con el formato";
    }

    if (form.email_new1.trim() != form.email_new2.trim()){
        errors.email_newAll = "Los correos son distintos";
    }

    return errors;
};

let styles = {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#dc3545"
}

const FormConfiguracionEmail = ({data}) => {

    const initialForm = {
        id: 1,
        email: data.mail,
        email_new1: '',
        email_new2: ''
    };

    const {
        form, 
        errors, 
        //loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    } = useFormUEM(initialForm, validationsForms);
    

    return (
        <div className="modal fade" id="configuracionEmailModal" tabIndex="-1" aria-labelledby="configuracionEmailModalLabel" aria-hidden="true">
                <form onSubmit={handleSubmit}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{ padding: "1rem 1rem", borderBottom: "1px solid #dee2e6" }}>
                            <h5 className="modal-title" id="configuracionEmailModalLabel">Cambiar mi Email</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">

                                    <div className="row">
                                        <div className="col-sm-12">
                                            <label className="col-form-label">Email actual:</label>
                                            <input type="text" className="form-control" id="email" name="email" defaultValue={data.mail} disabled />
                                        </div>
                                        
                                    </div>
                                    <br /><br />
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <label className="col-form-label">Nuevo email:</label>
                                            <input type="text" className="form-control" id="email_new1" name="email_new1" onBlur={handleBlur} onChange={handleChange} value={form.email_new1} required />
                                            {errors.email_new1 && <p style={styles} > {errors.email_new1}</p>}
                                        </div>

                                        <div className="col-sm-6">
                                            <label className="col-form-label">Verificar email:</label>
                                            <input type="text" className="form-control" id="email_new2" name="email_new2" onBlur={handleBlur} onChange={handleChange} value={form.email_new2} required />
                                            {errors.email_new2 && <p style={styles} > {errors.email_new2}</p>}
                                        </div>
                                        {errors.email_newAll && <p style={styles} > {errors.email_newAll}</p>}
                                    </div>
                                    
                            </div>
                            <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </div>
                    </div>
                </form>
                { response && <Message msg="Se actualizo correctamente." bgColor="#198754" /> }
            </div>

            
    );

}

export default FormConfiguracionEmail;
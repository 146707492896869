import React from 'react';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import MisAvisosSearchBottom from '../../../components/mis-avisos/MisAvisosSearchBottom';
import MisAvisosSearchTop from '../../../components/mis-avisos/MisAvisosSearchTop';
import MisAvisosList from '../../../components/mis-avisos/MisAvisosList';
//export default function AvisoLista({search}) {
const AvisoLista = ({search, jobList, jobDetail, setJobList }) => {
    
        //console.log("IMPRIME LISTA - JOB DETALLES:");
        //console.log(jobList);
    
        //if(!jobList) return null;

    console.log("LISTAs - PREV");
    if(!jobList) return null;
    console.log("LISTAs - COMP");

    return (
        <>

            { /*<MisAvisosSearchTop /> */ }
            
            <div>

                <MisAvisosList avisos = {jobList} />

            </div>
            
            { /*<MisAvisosSearchBottom /> */}
            
        </>
    )

}

export default AvisoLista
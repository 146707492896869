import React,{useState, useEffect} from 'react';
import { findCandidato, findCandidatoExperienciaLaboral, findConocimientoByIDCandidato, findFormacionAcademicaByIDCandidato, findOtrosConocimientosByIDCandidato } from '../../../services/apis';

import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import CvPostulanteIzquierda from './CvPostulanteIzquierdo';
import CvPostulanteDerecho from './CvPostulanteDerecho';

export default function Section1({candidatoID}) {

    const [search, setSearch] = useState(null);
    const [experiencia, setExperiencia] = useState(null);

    const [forAcademicaList, setforAcademicaList] = useState(null);
    const [conocimientoList, setConocimientoList] = useState(null);
    const [otroConocimientoList, setOtroConocimientoList] = useState(null);

    useEffect( () => {

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                const res = await findCandidato(candidatoID);
                console.log(res);
                setSearch(res);

            } catch (e) {
                console.error(e);
            }

            // LISTA EXPERIENCIA LABORAL
            try {
                const res = await findCandidatoExperienciaLaboral(candidatoID);
                console.log(res);
                setExperiencia(res);

            } catch (e) {
                console.error(e);
            }

            // FORMACION
            try {

                const formList = await findFormacionAcademicaByIDCandidato(candidatoID);
                
                console.log(formList);
                setforAcademicaList(formList);

            } catch (e) {
                console.error(e);
            }

            // CONOCIMIENTOS Y HABILIDADES
            try {

                const ConoList = await findConocimientoByIDCandidato(candidatoID);
                
                console.log(ConoList);
                setConocimientoList(ConoList);

            } catch (e) {
                console.error(e);
            }

            // OTROS CONOCIMIENTOS Y HABILIDADES
            try {

                const ConOtroList = await findOtrosConocimientosByIDCandidato(candidatoID);
                
                console.log(ConOtroList);
                setOtroConocimientoList(ConOtroList);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);


    return (
        <section >
            <div className="pxp-container">
                <div className="row" style={{"textAlign":"right", "display":"none"}}>
                    <div className="row justify-content-between align-items-center">
                        <div>
                            <a href="/publicar-aviso" className="btn rounded-pill pxp-nav-btn">Publicar Trabajo</a>
                        </div>
                    </div>
                </div>
                <div className="row mt-4 mt-lg-5 ">
                    <div className="col-lg-5 col-xl-4 col-xxl-4">
                        <CvPostulanteIzquierda search ={search} />
                    </div>
                    <div className="col-lg-7 col-xl-8 col-xxl-8">
                        <CvPostulanteDerecho experiencia={experiencia} foracademica={forAcademicaList} conocimientos={conocimientoList} otroConocimiento={otroConocimientoList} />
                    </div>
                </div>
            </div>
        </section>
    )
}
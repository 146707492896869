import React from 'react';

export default function Modals({title, description}) {
    return (
        <>
            <div className="modal fade pxp-user-modal" id="pxp-detallesreclutador01-use-modal" aria-hidden="true" aria-labelledby="detallesModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title text-center mt-4" id="detallesModal">Plan Bienvenida</h5>
                            <form className="mt-4" >
                                <div className="mt-4 text-center pxp-modal-small" >
                                    El plan bienvenida actualmente ya se encuentra en uso.
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade pxp-user-modal" id="pxp-detallesreclutador01-modal" aria-hidden="true" aria-labelledby="detallesModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title text-center mt-4" id="detallesModal">Plan Bienvenida</h5>
                            <form className="mt-4" >
                                <div className="mt-4 text-center pxp-modal-small" >
                                    El plan bienvenida cuenta con una duración de 2 meses para poder publicar ilimitadamente.
                                    Selecciona talentos en nuestra base de datos, dispones de 3 avisos urgentes y 3 avisos destacados. 
                                    Los anuncios publicados cuentan con una vigencia de 60 días en la plataforma.
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade pxp-user-modal" id="pxp-detallesreclutador02-modal" aria-hidden="true" aria-labelledby="detallesModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title text-center mt-4" id="detallesModal">Plan Gratuito</h5>
                            <form className="mt-4" >
                                <div className="mt-4 text-center pxp-modal-small" >
                                    Podrás adquirirlo luego del plan de bienvenida. Anuncie gratuitamente 3 avisos de trabajo. 
                                    Selecciona talentos en nuestra base de datos. Los anuncios tienen vigencia de 60 días, desde su publicación.
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade pxp-user-modal" id="pxp-detallesreclutador03-modal" aria-hidden="true" aria-labelledby="detallesModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title text-center mt-4" id="detallesModal">Plan Emprendedor “S/.94.99”</h5>
                            <form className="mt-4" >
                                <div className="mt-4 text-center pxp-modal-small" >
                                    El plan emprendedor, te invita a crecer. Publica máximo 8 anuncios de trabajo al mes, 
                                    selecciona talentos en nuestra base de datos, disponemos de 3 avisos urgentes y 3 avisos destacados. 
                                    Además, promocionamos tus avisos en nuestras redes sociales.
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade pxp-user-modal" id="pxp-detallesreclutador04-modal" aria-hidden="true" aria-labelledby="detallesModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <h5 className="modal-title text-center mt-4" id="detallesModal">Plan Ilimitado  “S/.199.90”</h5>
                            <form className="mt-4" >
                                <div className="mt-4 text-center pxp-modal-small" >
                                    Un poder ilimitado, anuncia de forma ilimitada con vigencia máxima de 60 días. 
                                    Selecciona talentos en nuestra base de datos. Además, disfruta de 5 avisos urgentes y 5 avisos destacados. 
                                    Cuenta también con promoción para tus avisos de trabajo en nuestras redes sociales.
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
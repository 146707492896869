import React,{useState, useEffect} from 'react';
import FrmEditarAviso from './FrmEditarAviso';
import { findDepartamentos, findEmpleoByIDandEmpresaID } from '../../../services/apis';

export default function Section1({AvisoID}) {

    const [search, setSearch] = useState(null);
    const [departamentos, setDepartamentos] = useState(null);

    useEffect( () => {

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                const res = await findEmpleoByIDandEmpresaID(AvisoID);
                console.log(res);
                setSearch(res);

            } catch (e) {
                console.error(e);
            }

            try {
                const res = await findDepartamentos(1);
                console.log(res);
                setDepartamentos(res);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);
    
    if(!search) return null;
    //console.log("CNT" + search.length);
    
    return (
        <FrmEditarAviso search={search} departamentos={departamentos} />
    )
}
import React,{useState, useEffect} from 'react';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import FrmPublicarAviso from './FrmPublicarAviso';
import { findDepartamentos, findDistrito, findPronvicia } from '../../../services/apis';

export default function Section1() {

    const [departamentos, setDepartamentos] = useState(null);
    const [provincias, setProvincias] = useState(null);
    const [distritos, setDistritos] = useState(null);

    useEffect( () => {

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                const res = await findDepartamentos(1);
                console.log(res);
                setDepartamentos(res);

            } catch (e) {
                console.error(e);
            }

            /*try {
                const res = await findPronvicia(1);
                console.log(res);
                setProvincias(res);

            } catch (e) {
                console.error(e);
            }

            try {
                const res = await findDistrito(1);
                console.log(res);
                setDistritos(res);

            } catch (e) {
                console.error(e);
            }*/

        };

        fetchData();

    },[]);

    return (
        <>
            <FrmPublicarAviso departamentos={departamentos} /*provincias={provincias} distritos={distritos}*/ />
        </>
    )
}
import React from 'react';
import Section0 from './sections/Section0.js';
import Section2 from './sections/Section2.js';

export default function Configuracion() {

    return (
        <>
            <Section0 />
        </>
    );
}
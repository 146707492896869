import { useState } from "react";
import { helpHttp } from "../helpers/helpHttp";
import { useNavigate } from "react-router-dom";

let urlEndPoint = process.env.REACT_APP_API_URL;

export const useFormA = (initialForm, validateForm) => {

    const[form, setForm] = useState(initialForm);
    const[errors, setErros] = useState({});
    const[loading, setLoading] = useState(false);
    const[response, setResponse] = useState(null);

    const company = JSON.parse(localStorage.getItem('company'));
    const GetUserID = company.codeId;
    
    const handleChange = (e) => {
        const {name, value} = e.target;
        setForm({
            ...form,
            [name]:value,
        });
    };
    const handleBlur = (e) => {
        handleChange(e);
        setErros(validateForm(form));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErros(validateForm(form));

        const{ 
        idempleo,
        idCompania,
        idTipoEmpleo,
        idExperiencia,
        idModalidadEmpleo,
        idTipoSalario,
        idTipoContrato,
        destacada,
        urgente,
        ocultarNombreEmpresa,
        mostrarEmail,
        mostrarTelefono,
        mostrarDireccion,
        cargo,
        categoria,
        descripcion,
        responsabilidades,
        requisitos,
        habilidades,
        distrito,
        habilitaSalario,
        nombre,
        salario,
        publicacion,
        inicio,
        fin,
        vigente} = form;

        if(Object.keys(errors).length === 0){
            //alert("Formulario Enviado");

            let endPoint = `${urlEndPoint}/empleos`;
            let empleoID = idempleo ? idempleo : null;

            let optionsPars = {
                body: {
                    id: empleoID,
                    compania : {
                        id: `${GetUserID}`,
                    },
                    tipoEmpleoEnum : {
                        id : `${idTipoEmpleo}`,
                    },
                    experienciaEnum : {
                        id: `${idExperiencia}`,
                    },
                    modalidadEmpleoEnum : {
                        id: `${idModalidadEmpleo}`,
                    },
                    tipoSalarioEnum : {
                        id: `${idTipoSalario}`,
                    },
                    tipoContratoEnum : {
                        id: `${idTipoContrato}`,
                    },
                    destacada : `${destacada}`,
                    urgente : `${urgente}`,
                    ocultarNombreEmpresa : `${ocultarNombreEmpresa}`,
                    mostrarEmail : `${mostrarEmail}`,
                    mostrarTelefono : `${mostrarTelefono}`,
                    mostrarDireccion : `${mostrarDireccion}`,
                    cargo : `${cargo}`,
                    categoria : `${categoria}`,
                    descripcion : `${descripcion}`,
                    responsabilidades : `${responsabilidades}`,
                    requisitos : `${requisitos}`,
                    habilidades : `${habilidades}`,
                    distrito : {
                        id: `${distrito}`,
                    },
                    habilitaSalario : `${habilitaSalario}`,
                    nombre : `${nombre}`,
                    publicacion : `${publicacion}`,
                    inicio : `${inicio}`,
                    fin : `${fin}`,
                    vigente : `${vigente}`,
                    salario : `${salario}`
                }
            }

            console.log(optionsPars);

            try {

                helpHttp()
                .post(endPoint, optionsPars)
                .then( (res) => {
                    //console.log(res);
                    
                    setLoading(false);
                    setResponse(true);
                    setTimeout(() => setResponse(false), 5000);
                    setForm(initialForm);

                    if ('id' in res) {
                        window.location.href = "/mis-avisos/";
                    }
                })
                .catch(error => {
                    //this.setState({ errorMessage: error.toString() });
                    //console.log('There was an error!' + error);
                    console.error('There was an error!', error);
                });

            } catch (error) {
                //alert("error");
                console.log('There was an error!' + error);
                console.error(error);
            }
            
            //redirecionar();
            //window.location.href = "/mis-avisos/";

        } else {
            return
        }
    };

    const navigate = useNavigate();
    function redirecionar() {
        navigate("/mis-avisos/");
    }
    

    return{
        form, 
        errors, 
        loading, 
        response, 
        handleChange, 
        handleBlur, 
        handleSubmit
    }

};
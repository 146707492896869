import React from 'react';
import imgPost from '../../../assets/images/single-blog-post.jpg';
import phSmall from '../../../assets/images/ph-small.jpg';
import MisAvisosSearchBottom from '../../../components/mis-avisos/MisAvisosSearchBottom';
import MisAvisosSearchTop from '../../../components/mis-avisos/MisAvisosSearchTop';
import MisAvisosList from '../../../components/mis-avisos/MisAvisosList';
//export default function AvisoLista({search}) {
const InicioDashboard = ({search, jobList, jobDetail, setJobList }) => {
    
    const ArrDestacado = [];
    const ArrUrgente = [];

    console.log("LISTAs - PREV");
    if(!jobList) return null;
    if(!jobDetail) return null;

    
    jobList.forEach(({id,  urgente, destacada },index)=>{
        if (urgente == 1) {
            //ArrUrgente[index] = urgente;
            ArrUrgente.push(parseInt(index));
        }
        if (destacada == 1) {
            //ArrDestacado[index] = destacada;
            ArrDestacado.push(parseInt(index));
        }
    });

    const contarTotal = jobList.length;
    //contarUrgente = jobList.length;
    const contarUrgente = ArrUrgente.length;
    const contarDestacado = ArrDestacado.length;
    const contarPostulantes = jobDetail.length;
    console.log("LISTAs - COMP" + contarTotal);

    return (
        <>
                <div className="row mt-4 mt-lg-5 align-items-center" >
                    <div className="col-sm-6 col-xxl-3">
                        <div className="pxp-dashboard-stats-card bg-primary bg-opacity-10 mb-3 mb-xxl-0">
                            <div className="pxp-dashboard-stats-card-icon text-primary">
                                <span className="fa fa-file-text-o"></span>
                            </div>
                            <div className="pxp-dashboard-stats-card-info">
                                <div className="pxp-dashboard-stats-card-info-number">{contarTotal}</div>
                                <div className="pxp-dashboard-stats-card-info-text pxp-text-light"><a href='/mis-avisos'>Publicaciones</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="pxp-dashboard-stats-card bg-primary bg-opacity-10 mb-3 mb-xxl-0">
                            <div className="pxp-dashboard-stats-card-icon text-success">
                                <span className="fa fa-user-circle-o"></span>
                            </div>
                            <div className="pxp-dashboard-stats-card-info">
                                <div className="pxp-dashboard-stats-card-info-number">{contarPostulantes}</div>
                                <div className="pxp-dashboard-stats-card-info-text pxp-text-light"><a href='/buscar-candidato'>Postulantes</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="pxp-dashboard-stats-card bg-primary bg-opacity-10 mb-3 mb-xxl-0">
                            <div className="pxp-dashboard-stats-card-icon text-warning">
                                <span className="fa fa-envelope-o"></span>
                            </div>
                            <div className="pxp-dashboard-stats-card-info">
                                <div className="pxp-dashboard-stats-card-info-number">{contarUrgente}</div>
                                <div className="pxp-dashboard-stats-card-info-text pxp-text-light"><a href='/mis-avisos'>Avisos urgentes</a></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xxl-3">
                        <div className="pxp-dashboard-stats-card bg-primary bg-opacity-10 mb-3 mb-xxl-0">
                            <div className="pxp-dashboard-stats-card-icon text-danger">
                                <span className="fa fa-bell-o"></span>
                            </div>
                            <div className="pxp-dashboard-stats-card-info">
                                <div className="pxp-dashboard-stats-card-info-number">{contarDestacado}</div>
                                <div className="pxp-dashboard-stats-card-info-text pxp-text-light"><a href='/mis-avisos'>Avisos destacados</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            
        </>
    )

}

export default InicioDashboard
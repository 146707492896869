import React from 'react';
import { Routes ,Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {decode} from 'html-entities';
import Inicio from './pages/inicio/Inicio';
import Empresa from './pages/empresa/Empresa';
import Configuracion from './pages/configuracion/Configuracion';
import MisAvisos from './pages/misAvisos/MisAvisos';
import PublicarAviso from './pages/publicarAviso/PublicarAviso';
import BuscarCandidato from './pages/buscarCandidatos/Buscarcandidatos';
import CvPostulante from './pages/cvPostulante/CvPostulante';
import GestionCandidato from './pages/gestionCandidato/GestionCandidato';
import Planes from './pages/planes/Planes';
import ConfirmaOrden from './pages/confirmaOrden/ConfirmaOrden';
import MuchasGracias from './pages/muchasGracias/MuchasGracias';
import MuchasGraciasCorporativo from './pages/muchasGraciasCorporativo/MuchasGraciasCorporativo';
import CvVerPostulante from './pages/cvVerPostulante/CvVerPostulante';
import Login from './pages/login/Login';
import AvisoEditar from './pages/avisoEditar/AvisoEditar';
import Privacidad from './pages/privacidad/Privacidad';

function App() {

    const [cookies, setCookie, removeCookie] = useCookies(['accessTokenCompany', 'company']);

    const company = decode(cookies.company);
    const GetUserID = company.codeId;
    
    const ubicacion = window.location.pathname;
    const token = localStorage.getItem('accessTokenCompany');
    //console.log("TOKEN: " + token);    

    if(!token) {
        
        if(!GetUserID){
            if(ubicacion == "/login"){

            } else {
                window.location.href = "/login";
            }
        } else {
            localStorage.setItem('accessTokenCompany', cookies.accessToken );
            localStorage.setItem('company', JSON.stringify(company));
            window.location.href = "/";
        }
        
    }
    
    return (
        <Routes>
            <Route path='/' element={<Inicio/>} />
            <Route path='/empresa' element={<Empresa/>} />
            <Route path='/configuracion' element={<Configuracion/>} />
            <Route path='/mis-avisos' element={<MisAvisos/>} />
            <Route path='/publicar-aviso' element={<PublicarAviso/>} />
            <Route path='/editar-aviso/:id' element={<AvisoEditar/>} />
            <Route path='/buscar-candidato' element={<BuscarCandidato/>} />
            <Route path='/cv-postulante/:id' element={<CvPostulante/>} />
            <Route path='/ver-cv-postulante/:id' element={<CvVerPostulante/>} />
            <Route path='/gestion-candidato/:id' element={<GestionCandidato/>} />
            <Route path='/planes' element={<Planes/>} />
            <Route path='/confirma-orden' element={<ConfirmaOrden/>} />
            <Route path='/muchas-gracias' element={<MuchasGracias/>} />
            <Route path='/muchas-gracias-corporativo' element={<MuchasGraciasCorporativo/>} />
            <Route path='/login' element={<Login/>} />
            <Route path='/privacidad' element={<Privacidad />} />
        </Routes>
    );
}

      
export default App;

import React from 'react';
import { useNavigate, Link, useLocation } from "react-router-dom";
import logo from '../../assets/images/Tconvoco-logo-negativo.png';

let urlPublico = process.env.REACT_APP_URL_PUBLIC;

export default function FooterExt() {
    return (
        <footer className="pxp-main-footer">
            <div className="pxp-main-footer-top pt-100 pb-100">
                <div className="pxp-container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-5 col-xxl-4 mb-4">
                            <div className="pxp-footer-logo">
                                <a href={urlPublico} className="pxp-animate"><img src={logo} alt="-" style={{ maxWidth: "50%" }} /></a>
                            </div>
                            <div className="pxp-footer-section mt-3 mt-md-4">
                                <h3>Llámanos</h3>
                                <div className="pxp-footer-phone">(123) 456-7890</div>
                            </div>
                            <div className="mt-3 mt-md-4 pxp-footer-section">
                                <div className="pxp-footer-text">
                                    Cal. Salvador Dali<br/>
                                    Nro. 449 Urb. San Borja Norte<br/>
                                    Lima - Lima - San Borja
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-7 col-xxl-8">
                            <div className="row">
                                <div className="col-md-6 col-xl-4 col-xxl-3 mb-4">
                                    <div className="pxp-footer-section">
                                        <h3>Sobre Nosotros</h3>
                                        <ul className="pxp-footer-list">
                                            <li><a href="/nosotros">¿Quiénes somos?</a></li>
                                            <li><a href="/#">Contactanos</a></li>
                                            <li><a href="/#">Aviso legal y privacidad</a></li>
                                            <li><a href="/blog">Blog</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 col-xxl-3 mb-4">
                                    <div className="pxp-footer-section">
                                        <h3>Provincia</h3>
                                        <ul className="pxp-footer-list">
                                            <li><a href="/busqueda-trabajo">JUNIN</a></li>
                                            <li><a href="/busqueda-trabajo">IQUITOS</a></li>
                                            <li><a href="/busqueda-trabajo">LIMA</a></li>
                                            <li><a href="/busqueda-trabajo">LA LIBERTAD</a></li>
                                            <li><a href="/busqueda-trabajo">PIURA</a></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 col-xxl-3 mb-4">
                                    <div className="pxp-footer-section">
                                        <h3>Tipo</h3>
                                        <ul className="pxp-footer-list">
                                            <li><a href="/busqueda-trabajo">JORNADA COMPLETA</a></li>
                                            <li><a href="/busqueda-trabajo">MEDIA JORNADA</a></li>
                                            <li><a href="/busqueda-trabajo">CONTRATO POR OBRA</a></li>
                                            <li><a href="/busqueda-trabajo">PRACTICAS</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 col-xxl-3 mb-4">
                                    <div className="pxp-footer-section">
                                        <h3>Potencia tu búsqueda</h3>
                                        <ul className="pxp-footer-list">
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pxp-main-footer-bottom" style={{backgroundColor: 'var(--pxpMainColorLight)'}}> {/*pxp-main-footer-bottom*/}
                <div className="pxp-container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-auto">
                            <div className="pxp-footer-copyright pxp-text-light">© 2022 T-convoco. Todos los derechos reservados.</div>
                        </div>
                        <div className="col-lg-auto">
                            <div className="pxp-footer-social mt-3 mt-lg-0">
                                <ul className="list-unstyled">
                                    <li><a href="/#"><span className="fa fa-facebook"></span></a></li>
                                    <li><a href="/#"><span className="fa fa-twitter"></span></a></li>
                                    <li><a href="/#"><span className="fa fa-instagram"></span></a></li>
                                    <li><a href="/#"><span className="fa fa-linkedin"></span></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
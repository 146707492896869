import React from 'react';
import checkBlack from '../../../assets/images/check.svg'; 
import checkLight from '../../../assets/images/check-light.svg'; 
import img3 from '../../../assets/images/nosotros-03.png'; 
import img4 from '../../../assets/images/nosotros-04.png'; 
import img5 from '../../../assets/images/nosotros-05.png'; 
import Modals from './Modals';

export default function Section1() {
    return (
        <>
            <div className="mt-4 mt-lg-5">
                <div class="pxp-container">
                    <h1>Planes de Pago</h1>
                    <h2 class="pxp-section-h2 text-center mt-4 mt-lg-5 d-lg-none">50% de descuento en todos nuestros planes. <br />
                    ¿Necesitas créditos? <br />
                    Compra solo las que necesites.</h2>
                    
                    <div class="row mt-4 mt-lg-5 d-lg-none">
                        <div class="col-xxl-6">
                            <div class="row text-center">
                                <div class="col-xxl-4"> </div>
                                <div class="col-xxl-2"> <p style={{"font-size": "60px", "padding-top": "65px"}}>-</p></div>
                                <div class="col-xxl-3"> <p style={{"font-size": "150px", "font-weight": "500"}}>3</p></div>
                                <div class="col-xxl-2"> <p style={{"font-size": "60px", "padding-top": "65px"}}>+</p></div>
                            </div>
                        </div>
                        <div class="col-xxl-6">
                            <div class="row">
                                <div class="col-xxl-2"> <p style={{"font-size": "35px", "text-align": "right"}}>S/.</p></div>
                                <div class="col-xxl-4"> <p style={{"font-size": "150px", "font-weight": "500"}}> 60 </p></div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="pxp-plans-card-1-cta text-center d-lg-none">
                        <a href="/confirma-orden" class="btn rounded-pill pxp-card-btn">Comprar créditos</a>
                    </div>

                    <h2 class="pxp-section-h2 text-center mt-4 mt-lg-5 d-lg-none">¿Necesitas más créditos? <br />
                    Adquiere cualquier de nuestros planes.
                    </h2>
                    <div class="row mt-3 mt-md-4 pxp-animate-in pxp-animate-in-top">
                        
                    <div class="col-md-6 col-xl-6 col-xxl-6 pxp-plans-card-1-container">
                            <div class="pxp-plans-card-1 pxp-is-featured">
                                <div class="pxp-plans-card-1-top">
                                    <div class="pxp-plans-card-1-title">Plan Bienvenida</div>
                                    <div class="pxp-plans-card-1-price ">
                                        <div className="pxp-plans-price-monthly">
                                            Bienvenido<span className="pxp-period"></span>
                                        </div>
                                    </div>
                                    <div class="pxp-plans-card-1-list">
                                        <ul class="list-unstyled">
                                            <li><img src={checkLight} alt="" />Publicaciones ilimitadas por 2 meses. Totalmente gratis</li>
                                            <li><img src={checkLight} alt="" />Selecciona talentos en nuestra base de datos</li>
                                            <li><img src={checkLight} alt="" />3 avisos urgentes</li>
                                            <li><img src={checkLight} alt="" />3 avisos destacados</li>
                                            <li><img src={checkLight} alt="" />Vigencia del anuncio de 60 días.</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="pxp-plans-card-1-bottom">
                                    <div class="pxp-plans-card-1-cta text-center">
                                        {/*<a href="/muchas-gracias-corporativo" class="btn rounded-pill pxp-card-btn">Cotizador</a>*/}
                                        {/*<a href="https://wa.me/51959097301?text=%C2%A1Hola%2C+quisiera+m%C3%A1s+informaci%C3%B3n+sobre+sus+servicios%21" target="_blank" class="btn rounded-pill pxp-card-btn">Publicar Gratis</a>*/}
                                        <a data-bs-toggle="modal" href="#pxp-detallesreclutador01-use-modal" class="btn rounded-pill pxp-card-btn">Publicar Gratis</a>
                                    </div>
                                    <br />
                                        <div className='text-center'>
                                            <a data-bs-toggle="modal" href="#pxp-detallesreclutador01-modal" role="button" style={{'color':'#ffffff'}}>Ver detalle</a>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-6 col-xxl-6 pxp-plans-card-1-container">
                            <div class="pxp-plans-card-1">
                                <div class="pxp-plans-card-1-top">
                                    <div class="pxp-plans-card-1-title">Plan Básico</div>
                                    <div class="pxp-plans-card-1-price">
                                        <div class="pxp-plans-price-monthly">
                                            S/ 0.00<span class="pxp-period">/mes</span>
                                        </div>
                                    </div>
                                    <div class="pxp-plans-card-1-list">
                                            <ul className="list-unstyled">
                                                <li><img src={checkBlack} alt="" />Publica 3 avisos de trabajo gratis.</li>
                                                <li><img src={checkBlack} alt="" />Selecciona talentos en nuestra base de datos.</li>
                                                <li><img src={checkBlack} alt="" />Máximo 3 publicaciones por al mes</li>
                                                <li><img src={checkBlack} alt="" />Vigencia del anuncio de 60 días.</li>
                                            </ul>
                                    </div>
                                </div>
                                <div class="pxp-plans-card-1-bottom">
                                    <div class="pxp-plans-card-1-cta text-center">
                                        {/*<a href="/confirma-orden" class="btn rounded-pill pxp-card-btn">Comprar</a>*/}
                                        <a href="https://wa.me/51959097301?text=%C2%A1Hola%2C+quisiera+m%C3%A1s+informaci%C3%B3n+sobre+sus+servicios%21" target="_blank" class="btn rounded-pill pxp-card-btn">Publicar</a>
                                    </div>
                                    <br />
                                        <div className='text-center'>
                                            <a data-bs-toggle="modal" href="#pxp-detallesreclutador02-modal" role="button" style={{'color':'#002745'}}>Ver detalle</a>
                                        </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-6 col-xxl-6 pxp-plans-card-1-container">
                            <div class="pxp-plans-card-1">
                                <div class="pxp-plans-card-1-top">
                                    <div class="pxp-plans-card-1-title">Plan Emprendedor</div>
                                    <div class="pxp-plans-card-1-price">
                                        <div class="pxp-plans-price-monthly">
                                            S/ 94.90<span class="pxp-period">/mes</span>
                                        </div>
                                    </div>
                                    <div class="pxp-plans-card-1-list">
                                            <ul className="list-unstyled">
                                                <li><img src={checkBlack} alt="" />Publica 8 avisos.</li>
                                                <li><img src={checkBlack} alt="" />Anuncio con vigencia de 60 días.</li>
                                                <li><img src={checkBlack} alt="" />Selecciona talentos en nuestra base de datos.</li>
                                                <li><img src={checkBlack} alt="" />3 avisos urgentes</li>
                                                <li><img src={checkBlack} alt="" />3 avisos destacados.</li>
                                                <li><img src={checkBlack} alt="" />Marketing en redes sociales</li>
                                            </ul>
                                    </div>
                                </div>
                                <div class="pxp-plans-card-1-bottom">
                                    <div class="pxp-plans-card-1-cta text-center">
                                        {/*<a href="/confirma-orden" class="btn rounded-pill pxp-card-btn">Comprar</a>*/}
                                        <a href="https://wa.me/51959097301?text=%C2%A1Hola%2C+quisiera+m%C3%A1s+informaci%C3%B3n+sobre+sus+servicios%21" target="_blank" class="btn rounded-pill pxp-card-btn">Comprar</a>
                                    </div>
                                    <br />
                                        <div className='text-center'>
                                            <a data-bs-toggle="modal" href="#pxp-detallesreclutador03-modal" role="button" style={{'color':'#002745'}}>Ver detalle</a>
                                        </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-6 col-xl-6 col-xxl-6 pxp-plans-card-1-container">
                            <div class="pxp-plans-card-1">
                                <div class="pxp-plans-card-1-top">
                                    <div class="pxp-plans-card-1-title">Plan Ilimitado</div>
                                    <div class="pxp-plans-card-1-price">
                                        <div class="pxp-plans-price-monthly">
                                            S/. 199.90<span class="pxp-period">/mes</span>
                                        </div>
                                    </div>
                                    <div class="pxp-plans-card-1-list">
                                            <ul className="list-unstyled">
                                                <li><img src={checkBlack} alt="" />Anuncios ilimitados con vigencia máxima de 60 días</li>
                                                <li><img src={checkBlack} alt="" />Selecciona talentos en nuestra base de datos.</li>
                                                <li><img src={checkBlack} alt="" />5 avisos urgentes</li>
                                                <li><img src={checkBlack} alt="" />5 avisos destacados</li>
                                                <li><img src={checkBlack} alt="" />Marketing en redes sociales</li>
                                            </ul>
                                    </div>
                                </div>
                                <div class="pxp-plans-card-1-bottom">
                                    <div class="pxp-plans-card-1-cta text-center">
                                        {/*<a href="/confirma-orden" class="btn rounded-pill pxp-card-btn">Comprar</a>*/}
                                        <a href="https://wa.me/51959097301?text=%C2%A1Hola%2C+quisiera+m%C3%A1s+informaci%C3%B3n+sobre+sus+servicios%21" target="_blank" class="btn rounded-pill pxp-card-btn">Comprar</a>
                                    </div>
                                    <br />
                                        <div className='text-center'>
                                            <a data-bs-toggle="modal" href="#pxp-detallesreclutador04-modal" role="button" style={{'color':'#002745'}}>Ver detalle</a>
                                        </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <Modals />
        </>
    );
};
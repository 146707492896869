import React,{useState, useEffect} from 'react';
import { findByParameters, findListaEmpleosByEmpresa } from '../../../services/apis';

import AvisoBarra from './AvisoBarra';
import AvisoLista from './AvisoLista';

export default function Section1() {

    const [search, setSearch] = useState(null);

    const [jobList, setJobList] = useState(null);
    const [jobDetail, setJobDetail] = useState(null);
    const [loading, setLoading] = useState(false);

    /*useEffect( () => {

        async function fetchData() {

            // LISTA POSTULACIONES
            try {
                const res = await findListaEmpleosByEmpresa(1);
                console.log(res);
                setSearch(res);

            } catch (e) {
                console.error(e);
            }

        };

        fetchData();

    },[]);*/

    const getPuesto = null;
    const getUbicacion = null;
    const getCompania = 1;

    useEffect( async () => {

        window.scrollTo(0, 0); // FORZA TOP

        //if ( (search === null) && (getPuesto === null && getUbicacion === null) ) return;
        //if (search === null) return;

        const jobTipoA = 1;
        const jobModalidadB = 1;
        const companiaID = 1;

        if(search){ //(getPuesto.length === 0 && getUbicacion.length === 0)
            const{jobsearch, joblocation, jobDestacado, jobUrgente, jobTipoContrato} = search;
            console.log("USE JOBSEARCH A");
            
            const jobsList = await findByParameters(jobsearch, joblocation, jobDestacado, jobUrgente, companiaID, jobTipoContrato);
            console.log(jobsList);
            setJobList(jobsList);
            //setSearch(jobsList);

        } else if( (getPuesto || getUbicacion) ){
            console.log("USE JOBSEARCH B");

            const jobsList = await findByParameters(getPuesto, getUbicacion, jobTipoA, jobModalidadB, companiaID);
            setJobList(jobsList);
            //setSearch(jobsList);

        } else if( getCompania ){
            console.log("USE JOBSEARCH C");

            const jobsList = await findByParameters("", "", "", "", 1);
            console.log(jobsList);
            
            setJobList(jobsList);
            //setSearch(jobsList);
        } else {
            console.log("USE JOBSEARCH D");
        }

    },[search]);
    

    const handleSearch = (data) => {
        setSearch(data);
    }


    return (
        <section >
            <div className="pxp-container">
                <div className="row">
                    <div className="col-lg-5 col-xl-4 col-xxl-3">
                        <AvisoBarra handleSearch = {handleSearch} puesto = {getPuesto} />
                    </div>
                    <div className="col-lg-7 col-xl-8 col-xxl-9">
                        <AvisoLista search ={search} jobList = {jobList} />
                    </div>
                </div>
            </div>
        </section>
    )
}